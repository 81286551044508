import { reactive } from 'vue';

// These breakpoints should be kept aligned with those in tailwind.config.js and _mediaQueries.scss
export const breakpoints = {
  all: 0,
  xxs: 360,
  xs: 450,
  sm: 600,
  md: 850,
  lg: 1150,
  xl: 1250,
  xxl: 1440,
  xxxl: 1600,
};

/**
 * These viewport properties indicate whether the current user's browser window is *at least* as wide as the given
 * breakpoint. So if the user's window is 4000px wide, then *all* the properties will be set to true.
 *
 * You can import this object directly into your component's `data` object, and then rely on it in your template
 * without needing to set up any additional resize listeners. Examples:
 *
 *    `v-if="viewport.lg"` means "display this if the viewport is at least as wide as our 'lg' breakpoint"
 *    `v-if="!viewport.md"` means "display this if the viewport is smaller than our 'md' breakpoint"
 */
export const viewport = reactive<{
  [key in keyof typeof breakpoints]: boolean;
}>({
  all: true,
  xxs: false,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
  xxxl: false,
});

export type ViewportSize = keyof typeof viewport;

const onViewportResize = (width) => {
  Object.entries(breakpoints).forEach(([breakpoint, minWidth]) => {
    viewport[breakpoint] = width >= minWidth;
  });
};

// Check if ResizeObserver exists (not in pipeline) before using it
const viewportObserver =
  typeof ResizeObserver === 'undefined'
    ? null
    : new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          // eslint-disable-next-line no-restricted-syntax
          for (const entry of entries) {
            if (!entry) return;
            /* 
            When at very specific viewport sizes Chromium based browsers had issues tracking the html DOM
            element width and this caused an infinite loop (UI issues and spamming our API). So use inner width instead 
            which should get the width of the viewport including any scrollbars that may be visible. 
            */
            onViewportResize(window.innerWidth);
          }
        });
      });
if (viewportObserver) {
  viewportObserver.observe(document.querySelector('html'));
}

export const viewportHeight = { px: 0 };
const viewportHeightObserver =
  typeof ResizeObserver === 'undefined'
    ? null
    : new ResizeObserver((entries) => {
        requestAnimationFrame(() => {
          // eslint-disable-next-line no-restricted-syntax
          for (const entry of entries) {
            if (!entry) return;
            // console.log(entry.contentRect.height);
            viewportHeight.px = entry.contentRect.height;
          }
        });
      });
if (viewportHeightObserver) {
  viewportHeightObserver.observe(document.querySelector('html'));
}

// @see https://stackoverflow.com/a/4819886/921476
export const isTouchDevice =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  document.documentElement.clientWidth < 840 ||
  document.body.clientWidth < 840;

export const elementIsScrolledToBottom = (el: HTMLElement) =>
  el.scrollTop === el.scrollHeight - el.offsetHeight;

export const elementHasHorizontalScroll = (el: HTMLElement) =>
  el && el?.clientWidth !== el.scrollWidth;

export const detectSafariOrPwa = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // @ts-ignore
  const isPWA = !!window.navigator.standalone;

  if (isPWA) {
    document.getElementById('app').classList.add('is-pwa');
  }
  if (isSafari) {
    document.getElementById('app').classList.add('is-safari');
  }
};

export const copyElementValue = (e: HTMLInputElement) => {
  e.select();
  e.setSelectionRange(0, 99999);

  navigator.clipboard.writeText(e.value).then();
};

export const getScrollbarWidth = () => {
  const el = document.createElement('div');
  el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
  document.body.appendChild(el);
  const width = el.offsetWidth - el.clientWidth;
  el.remove();
  return width;
};
