/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingEventLine } from './BillingEventLine';
import {
    BillingEventLineFromJSON,
    BillingEventLineFromJSONTyped,
    BillingEventLineToJSON,
} from './BillingEventLine';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface BillingEvent
 */
export interface BillingEvent {
    /**
     * 
     * @type {string}
     * @memberof BillingEvent
     */
    type: BillingEventTypeEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingEvent
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof BillingEvent
     */
    offers: number;
    /**
     * 
     * @type {number}
     * @memberof BillingEvent
     */
    estimatedCost: number;
    /**
     * 
     * @type {Array<BillingEventLine>}
     * @memberof BillingEvent
     */
    lines: Array<BillingEventLine>;
}

/**
* @export
* @enum {string}
*/
export enum BillingEventTypeEnum {
    Subscription = 'Subscription',
    Usage = 'Usage'
}


/**
 * Check if a given object implements the BillingEvent interface.
 */
export function instanceOfBillingEvent(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('date' in value)) return false;
    if (!('offers' in value)) return false;
    if (!('estimatedCost' in value)) return false;
    if (!('lines' in value)) return false;
    return true;
}

export function BillingEventFromJSON(json: any): BillingEvent {
    return BillingEventFromJSONTyped(json, false);
}

export function BillingEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'offers': json['offers'],
        'estimatedCost': json['estimatedCost'],
        'lines': ((json['lines'] as Array<any>).map(BillingEventLineFromJSON)),
    };
}

export function BillingEventToJSON(value?: BillingEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'offers': value['offers'],
        'estimatedCost': value['estimatedCost'],
        'lines': ((value['lines'] as Array<any>).map(BillingEventLineToJSON)),
    };
}

