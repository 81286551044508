/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
} from './Shift';

/**
 * 
 * @export
 * @interface CreateShiftResponse
 */
export interface CreateShiftResponse {
    /**
     * 
     * @type {Shift}
     * @memberof CreateShiftResponse
     */
    data: Shift;
}

/**
 * Check if a given object implements the CreateShiftResponse interface.
 */
export function instanceOfCreateShiftResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function CreateShiftResponseFromJSON(json: any): CreateShiftResponse {
    return CreateShiftResponseFromJSONTyped(json, false);
}

export function CreateShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ShiftFromJSON(json['data']),
    };
}

export function CreateShiftResponseToJSON(value?: CreateShiftResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftToJSON(value['data']),
    };
}

