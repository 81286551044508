/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulkCreateShiftDataBaseShiftData } from './BulkCreateShiftDataBaseShiftData';
import {
    BulkCreateShiftDataBaseShiftDataFromJSON,
    BulkCreateShiftDataBaseShiftDataFromJSONTyped,
    BulkCreateShiftDataBaseShiftDataToJSON,
} from './BulkCreateShiftDataBaseShiftData';
import type { BulkCreateShiftsShiftData } from './BulkCreateShiftsShiftData';
import {
    BulkCreateShiftsShiftDataFromJSON,
    BulkCreateShiftsShiftDataFromJSONTyped,
    BulkCreateShiftsShiftDataToJSON,
} from './BulkCreateShiftsShiftData';

/**
 * 
 * @export
 * @interface BulkCreateShiftData
 */
export interface BulkCreateShiftData {
    /**
     * 
     * @type {BulkCreateShiftDataBaseShiftData}
     * @memberof BulkCreateShiftData
     */
    baseShiftData: BulkCreateShiftDataBaseShiftData;
    /**
     * Each shift that needs to be created, with any overrides for that specific shift
     * @type {Array<BulkCreateShiftsShiftData>}
     * @memberof BulkCreateShiftData
     */
    shifts: Array<BulkCreateShiftsShiftData>;
}

/**
 * Check if a given object implements the BulkCreateShiftData interface.
 */
export function instanceOfBulkCreateShiftData(value: object): boolean {
    if (!('baseShiftData' in value)) return false;
    if (!('shifts' in value)) return false;
    return true;
}

export function BulkCreateShiftDataFromJSON(json: any): BulkCreateShiftData {
    return BulkCreateShiftDataFromJSONTyped(json, false);
}

export function BulkCreateShiftDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateShiftData {
    if (json == null) {
        return json;
    }
    return {
        
        'baseShiftData': BulkCreateShiftDataBaseShiftDataFromJSON(json['baseShiftData']),
        'shifts': ((json['shifts'] as Array<any>).map(BulkCreateShiftsShiftDataFromJSON)),
    };
}

export function BulkCreateShiftDataToJSON(value?: BulkCreateShiftData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'baseShiftData': BulkCreateShiftDataBaseShiftDataToJSON(value['baseShiftData']),
        'shifts': ((value['shifts'] as Array<any>).map(BulkCreateShiftsShiftDataToJSON)),
    };
}

