/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingEventLine
 */
export interface BillingEventLine {
    /**
     * 
     * @type {string}
     * @memberof BillingEventLine
     */
    name: BillingEventLineNameEnum;
    /**
     * 
     * @type {number}
     * @memberof BillingEventLine
     */
    estimatedCost: number;
}

/**
* @export
* @enum {string}
*/
export enum BillingEventLineNameEnum {
    Subscription = 'Subscription',
    AdditionalEmployees = 'Additional Employees'
}


/**
 * Check if a given object implements the BillingEventLine interface.
 */
export function instanceOfBillingEventLine(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('estimatedCost' in value)) return false;
    return true;
}

export function BillingEventLineFromJSON(json: any): BillingEventLine {
    return BillingEventLineFromJSONTyped(json, false);
}

export function BillingEventLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEventLine {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'estimatedCost': json['estimatedCost'],
    };
}

export function BillingEventLineToJSON(value?: BillingEventLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'estimatedCost': value['estimatedCost'],
    };
}

