/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface EmployeeLeaveReport
 */
export interface EmployeeLeaveReport {
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    shiftieReference?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    payrollId?: string;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeLeaveReport
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeLeaveReport
     */
    startsMidday: boolean;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeLeaveReport
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeLeaveReport
     */
    endsMidday: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    leaveTypeName: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveReport
     */
    allowanceUsed: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveReport
     */
    paid?: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    allowanceUnit: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    status: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EmployeeLeaveReport
     */
    assignedLocationIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveReport
     */
    leaveTypeColour: string;
}

/**
 * Check if a given object implements the EmployeeLeaveReport interface.
 */
export function instanceOfEmployeeLeaveReport(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('startDate' in value)) return false;
    if (!('startsMidday' in value)) return false;
    if (!('endDate' in value)) return false;
    if (!('endsMidday' in value)) return false;
    if (!('leaveTypeName' in value)) return false;
    if (!('allowanceUsed' in value)) return false;
    if (!('allowanceUnit' in value)) return false;
    if (!('status' in value)) return false;
    if (!('assignedLocationIds' in value)) return false;
    if (!('leaveTypeColour' in value)) return false;
    return true;
}

export function EmployeeLeaveReportFromJSON(json: any): EmployeeLeaveReport {
    return EmployeeLeaveReportFromJSONTyped(json, false);
}

export function EmployeeLeaveReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeLeaveReport {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftieReference': json['shiftieReference'] == null ? undefined : json['shiftieReference'],
        'name': json['name'],
        'payrollId': json['payrollId'] == null ? undefined : json['payrollId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'startsMidday': json['startsMidday'],
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'endsMidday': json['endsMidday'],
        'leaveTypeName': json['leaveTypeName'],
        'allowanceUsed': json['allowanceUsed'],
        'paid': json['paid'] == null ? undefined : json['paid'],
        'allowanceUnit': json['allowanceUnit'],
        'status': json['status'],
        'assignedLocationIds': json['assignedLocationIds'],
        'leaveTypeColour': json['leaveTypeColour'],
    };
}

export function EmployeeLeaveReportToJSON(value?: EmployeeLeaveReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftieReference': value['shiftieReference'],
        'name': value['name'],
        'payrollId': value['payrollId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'startsMidday': value['startsMidday'],
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'endsMidday': value['endsMidday'],
        'leaveTypeName': value['leaveTypeName'],
        'allowanceUsed': value['allowanceUsed'],
        'paid': value['paid'],
        'allowanceUnit': value['allowanceUnit'],
        'status': value['status'],
        'assignedLocationIds': value['assignedLocationIds'],
        'leaveTypeColour': value['leaveTypeColour'],
    };
}

