import i18n from '@/i18n';
import { Icon } from '@/lib/enum/Icon';
import { keyOfFirstTruthyValue } from '@/util/objectFunctions';
import spacetime from 'spacetime';
import { ScheduledBreak, ScheduleSettings } from '../../../api/v1';

export const tooEarlyToStartScheduledBreak = (
  scheduledBreak: ScheduledBreak | null,
  now: Date,
  timezone: string,
): boolean =>
  scheduledBreak?.startsAt
    ? spacetime(now, timezone).isBefore(scheduledBreak.startsAt)
    : false;

export const getBreakEndTime = (
  scheduledBreak: ScheduledBreak,
  timezone: string,
): Date => {
  const breakStartsAt = spacetime(scheduledBreak.startsAt, timezone);
  const breakEndsAt = breakStartsAt.add(
    scheduledBreak.durationInMinutes,
    'minute',
  );
  return breakEndsAt.toNativeDate();
};

export const getBreakTypeIcon = (paid: boolean): string =>
  keyOfFirstTruthyValue({
    [Icon.MugSaucer]: !paid,
    [Icon.BurgerSoda]: paid,
  }) || '';

export const getBreakTypeName = (
  scheduledBreak: ScheduledBreak,
  scheduleSettings: ScheduleSettings,
): string =>
  scheduledBreak.paid
    ? scheduleSettings.paidBreakName ?? i18n.tc('label.paidBreak')
    : scheduleSettings.unpaidBreakName ?? i18n.tc('label.unpaidBreak');
