
import { Icon, IconType } from '@/lib/enum/Icon';
import { PropType } from 'vue';

export default {
  name: 'CloseButton',
  props: {
    icon: {
      type: String as PropType<IconType>,
      default: Icon.Xmark,
    },
    lightMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Icon,
    };
  },
};
