/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayType
 */
export interface PayType {
    /**
     * 
     * @type {number}
     * @memberof PayType
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PayType
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof PayType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PayType
     */
    mode: PayTypeModeEnum;
    /**
     * 
     * @type {number}
     * @memberof PayType
     */
    hourlyRate: number;
}

/**
* @export
* @enum {string}
*/
export enum PayTypeModeEnum {
    Percentage = 'Percentage',
    Fixed = 'Fixed'
}


/**
 * Check if a given object implements the PayType interface.
 */
export function instanceOfPayType(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('mode' in value)) return false;
    if (!('hourlyRate' in value)) return false;
    return true;
}

export function PayTypeFromJSON(json: any): PayType {
    return PayTypeFromJSONTyped(json, false);
}

export function PayTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'mode': json['mode'],
        'hourlyRate': json['hourlyRate'],
    };
}

export function PayTypeToJSON(value?: PayType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'mode': value['mode'],
        'hourlyRate': value['hourlyRate'],
    };
}

