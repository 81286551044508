/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftTemplateAreaSessionsData } from './ShiftTemplateAreaSessionsData';
import {
    ShiftTemplateAreaSessionsDataFromJSON,
    ShiftTemplateAreaSessionsDataFromJSONTyped,
    ShiftTemplateAreaSessionsDataToJSON,
} from './ShiftTemplateAreaSessionsData';

/**
 * 
 * @export
 * @interface BulkCreateShiftTemplateShiftAreaSessionsData
 */
export interface BulkCreateShiftTemplateShiftAreaSessionsData {
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftTemplateShiftAreaSessionsData
     */
    shiftTemplateId: number;
    /**
     * 
     * @type {Array<ShiftTemplateAreaSessionsData>}
     * @memberof BulkCreateShiftTemplateShiftAreaSessionsData
     */
    shiftTemplateAreaSessions: Array<ShiftTemplateAreaSessionsData>;
}

/**
 * Check if a given object implements the BulkCreateShiftTemplateShiftAreaSessionsData interface.
 */
export function instanceOfBulkCreateShiftTemplateShiftAreaSessionsData(value: object): boolean {
    if (!('shiftTemplateId' in value)) return false;
    if (!('shiftTemplateAreaSessions' in value)) return false;
    return true;
}

export function BulkCreateShiftTemplateShiftAreaSessionsDataFromJSON(json: any): BulkCreateShiftTemplateShiftAreaSessionsData {
    return BulkCreateShiftTemplateShiftAreaSessionsDataFromJSONTyped(json, false);
}

export function BulkCreateShiftTemplateShiftAreaSessionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateShiftTemplateShiftAreaSessionsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftTemplateId': json['shiftTemplateId'],
        'shiftTemplateAreaSessions': ((json['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateAreaSessionsDataFromJSON)),
    };
}

export function BulkCreateShiftTemplateShiftAreaSessionsDataToJSON(value?: BulkCreateShiftTemplateShiftAreaSessionsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftTemplateId': value['shiftTemplateId'],
        'shiftTemplateAreaSessions': ((value['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateAreaSessionsDataToJSON)),
    };
}

