
import ToolTipIcon from '@/components/info/ToolTipIcon.vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

/**
 * The checkbox input allows for two-way binding.
 * You should handle the input within the parent component.
 * The `checkedValue` prop is what the component checks against the value.
 * If they're the same, it marks as checked.
 * The `value` prop is the value being passed between a child and parent component.
 */
export default {
  name: 'Checkbox',

  components: { ToolTipIcon },

  props: {
    labelText: {
      type: [String, TranslateResult],
      default: '',
    },
    // The value of the checkbox when it's checked
    checkedValue: {
      type: [String, Number, Boolean],
      default: true,
    },
    // A translation key for optional help (tooltip) text
    tooltip: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    // The 'value' prop is actually used to provide 'v-model' support in the parent component
    value: {
      type: [Array, String, Number, Boolean],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    // Changes the checbox style to add a border and gray background
    inset: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.disabled) return;
        this.$emit('input', val);
      },
    },
    isChecked() {
      // Allow our checkbox to support a v-model that can be an array (multiple checkbox values)
      // or just a single value, as per the examples of multiple checkboxes in the Vue documentation.
      return this.value instanceof Array
        ? this.value.includes(this.checkedValue)
        : this.value === this.checkedValue;
    },
  },
};
