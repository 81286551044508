/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkCreateShiftTemplateShiftAreaSessionsData,
  CreateShiftTemplateData,
  CreateShiftTemplateResponse,
  ListShiftTemplateAreaSessionsResponse,
  ListShiftTemplatesResponse,
  ShowShiftTemplateResponse,
  UpdateShiftTemplateData,
  UpdateShiftTemplateResponse,
} from '../models/index';
import {
    BulkCreateShiftTemplateShiftAreaSessionsDataFromJSON,
    BulkCreateShiftTemplateShiftAreaSessionsDataToJSON,
    CreateShiftTemplateDataFromJSON,
    CreateShiftTemplateDataToJSON,
    CreateShiftTemplateResponseFromJSON,
    CreateShiftTemplateResponseToJSON,
    ListShiftTemplateAreaSessionsResponseFromJSON,
    ListShiftTemplateAreaSessionsResponseToJSON,
    ListShiftTemplatesResponseFromJSON,
    ListShiftTemplatesResponseToJSON,
    ShowShiftTemplateResponseFromJSON,
    ShowShiftTemplateResponseToJSON,
    UpdateShiftTemplateDataFromJSON,
    UpdateShiftTemplateDataToJSON,
    UpdateShiftTemplateResponseFromJSON,
    UpdateShiftTemplateResponseToJSON,
} from '../models/index';

export interface BulkCreateShiftTemplateAreaSessionsRequest {
    bulkCreateShiftTemplateShiftAreaSessionsData?: BulkCreateShiftTemplateShiftAreaSessionsData;
}

export interface BulkDeleteShiftTemplateAreaSessionsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface CreateShiftTemplateRequest {
    _with?: Array<string>;
    createShiftTemplateData?: CreateShiftTemplateData;
}

export interface DeleteShiftTemplateRequest {
    id: number;
}

export interface ListShiftTemplateAreaSessionsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListShiftTemplatesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowShiftTemplateRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateShiftTemplateRequest {
    id: number;
    _with?: Array<string>;
    updateShiftTemplateData?: UpdateShiftTemplateData;
}

/**
 * 
 */
export class ShiftTemplateApi extends runtime.BaseAPI {

    /**
     * Bulk Create Shift Template Shift Area Sessions
     */
    async bulkCreateShiftTemplateAreaSessionsRaw(requestParameters: BulkCreateShiftTemplateAreaSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates/shift-area-sessions/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkCreateShiftTemplateShiftAreaSessionsDataToJSON(requestParameters['bulkCreateShiftTemplateShiftAreaSessionsData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk Create Shift Template Shift Area Sessions
     */
    async bulkCreateShiftTemplateAreaSessions(requestParameters: BulkCreateShiftTemplateAreaSessionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkCreateShiftTemplateAreaSessionsRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk Delete Shift Template Shift Area Sessions
     */
    async bulkDeleteShiftTemplateAreaSessionsRaw(requestParameters: BulkDeleteShiftTemplateAreaSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates/shift-area-sessions/bulk`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk Delete Shift Template Shift Area Sessions
     */
    async bulkDeleteShiftTemplateAreaSessions(requestParameters: BulkDeleteShiftTemplateAreaSessionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkDeleteShiftTemplateAreaSessionsRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new shift template
     * Create Shift Template
     */
    async createShiftTemplateRaw(requestParameters: CreateShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateShiftTemplateResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShiftTemplateDataToJSON(requestParameters['createShiftTemplateData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateShiftTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Create a new shift template
     * Create Shift Template
     */
    async createShiftTemplate(requestParameters: CreateShiftTemplateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateShiftTemplateResponse> {
        const response = await this.createShiftTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a specific shift template
     * Delete Shift template
     */
    async deleteShiftTemplateRaw(requestParameters: DeleteShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteShiftTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific shift template
     * Delete Shift template
     */
    async deleteShiftTemplate(requestParameters: DeleteShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShiftTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * 
     * List Shift Template Area Sessions
     */
    async listShiftTemplateAreaSessionsRaw(requestParameters: ListShiftTemplateAreaSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftTemplateAreaSessionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template-area-session.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates/shift-area-sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftTemplateAreaSessionsResponseFromJSON(jsonValue));
    }

    /**
     * 
     * List Shift Template Area Sessions
     */
    async listShiftTemplateAreaSessions(requestParameters: ListShiftTemplateAreaSessionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftTemplateAreaSessionsResponse> {
        const response = await this.listShiftTemplateAreaSessionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all shift templates
     * List Shift Templates
     */
    async listShiftTemplatesRaw(requestParameters: ListShiftTemplatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftTemplatesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftTemplatesResponseFromJSON(jsonValue));
    }

    /**
     * List all shift templates
     * List Shift Templates
     */
    async listShiftTemplates(requestParameters: ListShiftTemplatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftTemplatesResponse> {
        const response = await this.listShiftTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a shift template
     * Show Shift Template
     */
    async showShiftTemplateRaw(requestParameters: ShowShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowShiftTemplateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showShiftTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowShiftTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Show a shift template
     * Show Shift Template
     */
    async showShiftTemplate(requestParameters: ShowShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowShiftTemplateResponse> {
        const response = await this.showShiftTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a shift template
     * Update Shift Template
     */
    async updateShiftTemplateRaw(requestParameters: UpdateShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateShiftTemplateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateShiftTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-template.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShiftTemplateDataToJSON(requestParameters['updateShiftTemplateData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateShiftTemplateResponseFromJSON(jsonValue));
    }

    /**
     * Update a shift template
     * Update Shift Template
     */
    async updateShiftTemplate(requestParameters: UpdateShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateShiftTemplateResponse> {
        const response = await this.updateShiftTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
