import { Where } from '@/lib/realtime/weak/realtimeFiltering';
import {
  Absence,
  AbsenceCategory,
  AccessRole,
  BillingPlan,
  BudgetSummary,
  ClockInPortal,
  Company,
  CompanyNotificationChannel,
  CompanySetting,
  DaySummary,
  DigiticketsConnection,
  DigiticketsScheduleMap,
  Document,
  DocumentRead,
  EmergencyContact,
  Employee,
  EmployeeAbsenceSummary,
  EmployeeAttribute,
  EmployeeClockingProfile,
  EmployeeGroup,
  EmployeeInvite,
  EmployeeJobRole,
  EmployeeLeaveSummary,
  EmployeeMessage,
  EmployeeMessageRecipient,
  EmployeeNotificationChannel,
  EmployeePayDetails,
  EmployeePayPeriodSummary,
  EmployeePersonalDetails,
  FeaturesEnabled,
  History,
  IcalFeed,
  JobRole,
  LeaveAdjustment,
  LeavePeriod,
  LeavePolicy,
  LeaveRequest,
  LeaveType,
  ListEmployeePayPeriodSummariesRequest,
  ListEventEntitiesResponseDataEnum,
  ListTimesheetSummariesRequest,
  Location,
  NotificationSettings,
  Offer,
  OffersBehaviour,
  OffersQuota,
  OpenShiftResponse,
  PayCycle,
  PayPeriod,
  PayPeriodsSummary,
  PublicHoliday,
  Schedule,
  ScheduleEvent,
  ScheduleOverviewSummary,
  ScheduleTemplate,
  Shift,
  ShiftArea,
  ShiftSwap,
  ShiftTemplate,
  ShowBudgetSummaryRequest,
  ShowDaySummaryRequest,
  ShowLeavePolicyRequest,
  ShowScheduleOverviewRequest,
  Tag,
  TimesheetEntry,
  TimesheetEntrySummary,
  TrekksoftClientCredential,
  Unavailability,
  Upload,
  User,
  WorkPattern,
} from '../../../../api/v1';

const Entity = ListEventEntitiesResponseDataEnum;

// For cases when an entity is not defined in ListEventEntitiesResponseDataEnum, but we want to use it in the app.
export enum CustomEntity {
  EmployeePayPeriodSummary = 'EmployeePayPeriodSummary',
  FeaturesEnabled = 'FeaturesEnabled',
  PayPeriodsSummary = 'PayPeriodsSummary',
}

/**
 * To allow Typescript to understand the return type of our store functions, we need to map string representations
 * of our entities to actual Typescript interfaces.
 */
export type EntityInterfaceMap = {
  [CustomEntity.EmployeePayPeriodSummary]: EmployeePayPeriodSummary;
  [CustomEntity.FeaturesEnabled]: FeaturesEnabled;
  [CustomEntity.PayPeriodsSummary]: PayPeriodsSummary;

  [Entity.Absence]: Absence;
  [Entity.AbsenceCategory]: AbsenceCategory;
  [Entity.AccessRole]: AccessRole;
  [Entity.BillingPlan]: BillingPlan;
  [Entity.BudgetSummary]: BudgetSummary;
  [Entity.ClockInPortal]: ClockInPortal;
  [Entity.Company]: Company;
  [Entity.CompanyNotificationChannel]: CompanyNotificationChannel;
  [Entity.CompanySetting]: CompanySetting;
  [Entity.DaySummary]: DaySummary;
  [Entity.DigiticketsConnection]: DigiticketsConnection;
  [Entity.DigiticketsScheduleMap]: DigiticketsScheduleMap;
  [Entity.Document]: Document;
  [Entity.DocumentRead]: DocumentRead;
  [Entity.EmergencyContact]: EmergencyContact;
  [Entity.Employee]: Employee;
  [Entity.EmployeeAbsenceSummary]: EmployeeAbsenceSummary;
  [Entity.EmployeeAttribute]: EmployeeAttribute;
  [Entity.EmployeeClockingProfile]: EmployeeClockingProfile;
  [Entity.EmployeeGroup]: EmployeeGroup;
  [Entity.EmployeeInvite]: EmployeeInvite;
  [Entity.EmployeeJobRole]: EmployeeJobRole;
  [Entity.EmployeeLeaveSummary]: EmployeeLeaveSummary;
  [Entity.EmployeeMessage]: EmployeeMessage;
  [Entity.EmployeeMessageRecipient]: EmployeeMessageRecipient;
  [Entity.EmployeeNotificationChannel]: EmployeeNotificationChannel;
  [Entity.EmployeePayDetail]: EmployeePayDetails;
  [Entity.EmployeePersonalDetail]: EmployeePersonalDetails;
  [Entity.History]: History;
  [Entity.IcalFeed]: IcalFeed;
  [Entity.JobRole]: JobRole;
  [Entity.LeaveAdjustment]: LeaveAdjustment;
  [Entity.LeavePeriod]: LeavePeriod;
  [Entity.LeavePolicy]: LeavePolicy;
  [Entity.LeaveRequest]: LeaveRequest;
  [Entity.LeaveType]: LeaveType;
  [Entity.Location]: Location;
  [Entity.NotificationSetting]: NotificationSettings;
  [Entity.Offer]: Offer;
  [Entity.OffersBehaviour]: OffersBehaviour;
  [Entity.OffersQuota]: OffersQuota;
  [Entity.OpenShiftResponse]: OpenShiftResponse;
  [Entity.PayCycle]: PayCycle;
  [Entity.PayPeriod]: PayPeriod;
  [Entity.PublicHoliday]: PublicHoliday;
  [Entity.Schedule]: Schedule;
  [Entity.ScheduleEvent]: ScheduleEvent;
  [Entity.ScheduleOverviewSummary]: ScheduleOverviewSummary;
  [Entity.ScheduleTemplate]: ScheduleTemplate;
  [Entity.Shift]: Shift;
  [Entity.ShiftArea]: ShiftArea;
  [Entity.ShiftSwap]: ShiftSwap;
  [Entity.ShiftTemplate]: ShiftTemplate;
  [Entity.Tag]: Tag;
  [Entity.TimesheetEntry]: TimesheetEntry;
  [Entity.TimesheetEntrySummary]: TimesheetEntrySummary;
  [Entity.TrekksoftClientCredential]: TrekksoftClientCredential;
  [Entity.Unavailability]: Unavailability;
  [Entity.Upload]: Upload;
  [Entity.User]: User;
  [Entity.WorkPattern]: WorkPattern;
};

export type EntityFetcherRequestMap = {
  [CustomEntity.EmployeePayPeriodSummary]: ListEmployeePayPeriodSummariesRequest;
  [Entity.BudgetSummary]: ShowBudgetSummaryRequest;
  [Entity.DaySummary]: ShowDaySummaryRequest;
  [Entity.ScheduleOverviewSummary]: ShowScheduleOverviewRequest;
  [Entity.TimesheetEntrySummary]: ListTimesheetSummariesRequest;
  [Entity.LeavePolicy]: ShowLeavePolicyRequest;
};

export type EntityName = string & keyof EntityInterfaceMap;
export type WSEntityName = EntityName & ListEventEntitiesResponseDataEnum;

export type OrderBy<T extends object> = (
  | (keyof T & string)
  | `-${keyof T & string}`
)[];

export type GetParameterType<T> = T extends keyof EntityFetcherRequestMap &
  EntityName
  ? EntityFetcherRequestMap[T]
  : never;

export type EntityInstance<T extends EntityName> = EntityInterfaceMap[T];

export type Fetcher<
  InterfaceMap extends Record<string, object> = EntityInterfaceMap,
  Key extends keyof InterfaceMap = keyof InterfaceMap,
  ParamsMap extends Record<string, object> = EntityFetcherRequestMap,
> = (
  params: Key extends keyof ParamsMap ? ParamsMap[Key] : never,
  where: Where<InterfaceMap[Key]>,
  orderBy: OrderBy<InterfaceMap[Key]>,
) => Promise<InterfaceMap[Key][]>;

export type BatchFetcher<
  InterfaceMap extends Record<string, object> = EntityInterfaceMap,
  WSSupportName extends keyof InterfaceMap = WSEntityName,
  Key extends keyof InterfaceMap & WSSupportName = InterfaceMap & WSSupportName,
> = (ids: (number | string)[]) => Promise<InterfaceMap[Key][]>;

export enum PopulateStrategy {
  Disable = 'Disable',
  Subset = 'Subset', // Populate from any less strict collection
  Complete = 'Complete', // Populate only from a complete collection
}

export type EntityConfigRecord<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap,
  WSSupportName extends string,
  ParamsMap extends Record<string, object> = {},
> = {
  fetcher: Fetcher<InterfaceMap, Key, ParamsMap>;
  batchFetcher?: Key extends WSSupportName
    ? BatchFetcher<InterfaceMap, WSSupportName, Key>
    : never;
  entityMaker: (data: unknown) => InterfaceMap[Key];
  triggers?: WSSupportName[];
  orderBy?: OrderBy<InterfaceMap[Key]>;
  populateStrategy?: PopulateStrategy;
} & (Key extends WSSupportName
  ? { useWebsocket?: boolean }
  : // Force disabling the use of websocket for non-list entities
    { useWebsocket: false });

export type EntityConfig<
  InterfaceMap extends Record<string, object>,
  WSSupportName extends string = string,
  ParamsMap extends Record<string, object> = {},
> = {
  [K in keyof InterfaceMap]: EntityConfigRecord<
    InterfaceMap,
    K,
    WSSupportName,
    ParamsMap
  >;
};
