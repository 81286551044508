/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftAreaSessionsData
 */
export interface ShiftAreaSessionsData {
    /**
     * 
     * @type {number}
     * @memberof ShiftAreaSessionsData
     */
    shiftAreaId: number;
    /**
     * 
     * @type {Date}
     * @memberof ShiftAreaSessionsData
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShiftAreaSessionsData
     */
    endTime: Date;
}

/**
 * Check if a given object implements the ShiftAreaSessionsData interface.
 */
export function instanceOfShiftAreaSessionsData(value: object): boolean {
    if (!('shiftAreaId' in value)) return false;
    if (!('startTime' in value)) return false;
    if (!('endTime' in value)) return false;
    return true;
}

export function ShiftAreaSessionsDataFromJSON(json: any): ShiftAreaSessionsData {
    return ShiftAreaSessionsDataFromJSONTyped(json, false);
}

export function ShiftAreaSessionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftAreaSessionsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftAreaId': json['shiftAreaId'],
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
    };
}

export function ShiftAreaSessionsDataToJSON(value?: ShiftAreaSessionsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftAreaId': value['shiftAreaId'],
        'startTime': ((value['startTime']).toISOString()),
        'endTime': ((value['endTime']).toISOString()),
    };
}

