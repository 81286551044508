import { isLocalEnv } from '@/lib/environment';
import {
  CustomEventEmitter,
  Unsubscribe,
} from '@/lib/helpers/CustomEventEmitter';
import {
  FinalizationWeakMap,
  FinalizationWeakMapEventType,
} from '@/lib/helpers/FinalizationWeakMap';
import {
  createFilterFromWhereObject,
  EntityFilter,
  Where,
} from '@/lib/realtime/weak/realtimeFiltering';
import { compareEntities } from '@/lib/realtime/weak/realtimeSorting';
import {
  EntityConfig,
  EntityConfigRecord,
  OrderBy,
  PopulateStrategy,
} from '@/lib/realtime/weak/realtimeTypes';
import {
  BatchEntityEvent,
  batchEntityEventType,
  EntityEvent,
  entityEventType,
  SocketServiceEventMap,
  SocketServiceEventMapListeners,
  SocketServiceEventType,
} from '@/plugins/socket-service/socketTypes';
import { isSubset, objectDeepSort } from '@/util/objectFunctions';
import { CancellablePromise, makeCancellable } from '@/util/promiseFunctions';
import debounce from 'lodash.debounce';

const KeySeparator = ':';
type WeakEntityRepositoryItemKey<T extends string> =
  `${T}${typeof KeySeparator}${string}`;

export enum WeakEntityRepositoryUpdateKind {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface WeakEntityRepositoryResponse<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap,
> {
  entity: Key;
  isLoading: boolean;
  isLoaded: boolean;
  isFetching: boolean;
  data: InterfaceMap[Key][];
  promise: CancellablePromise<InterfaceMap[Key][]> | null;
  error: Error | null;
  refetch: () => CancellablePromise<InterfaceMap[Key][]>;
}

interface WeakEntityRepositoryItem<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap,
> extends WeakEntityRepositoryResponse<InterfaceMap, Key> {
  _parent: WeakRef<WeakEntityRepositoryResponse<InterfaceMap, Key>> | null;
  _filter: EntityFilter<InterfaceMap[Key]>;
  _reset: () => void;
}

export type ReactiveConstructor = <T extends object>(data: T) => T;

export enum WeakEntityRepositoryEventType {
  RESET = 'RESET',
  UPDATE = 'UPDATE',
  BATCH_UPDATE = 'BATCH_UPDATE',

  QUERY_CREATE = 'QUERY_CREATE',
  QUERY_FETCH = 'QUERY_FETCH',
  QUERY_FETCH_SUCCESS = 'QUERY_FETCH_SUCCESS',
  QUERY_FETCH_ERROR = 'QUERY_FETCH_ERROR',
  QUERY_FETCH_FINISH = 'QUERY_FETCH_FINISH',
  QUERY_FINALIZE = 'QUERY_FINALIZE',
}

export type QueryEmptyEvent<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap = keyof InterfaceMap,
> = {
  entityName: Key;
  where: Where<InterfaceMap[Key]>;
};

export type QueryEvent<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap = keyof InterfaceMap,
> = QueryEmptyEvent<InterfaceMap, Key> & {
  response: WeakEntityRepositoryResponse<InterfaceMap, Key>;
};

export type UpdateEvent<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap = keyof InterfaceMap,
> = {
  entityName: Key;
} & (
  | {
      kind:
        | WeakEntityRepositoryUpdateKind.CREATE
        | WeakEntityRepositoryUpdateKind.UPDATE;
      payload: InterfaceMap[Key];
    }
  | {
      kind: WeakEntityRepositoryUpdateKind.DELETE;
      payload: number | string;
    }
);

export type BatchedUpdateEvent<
  InterfaceMap extends Record<string, object>,
  Key extends keyof InterfaceMap = keyof InterfaceMap,
> = {
  entityName: Key;
} & (
  | {
      kind:
        | WeakEntityRepositoryUpdateKind.CREATE
        | WeakEntityRepositoryUpdateKind.UPDATE;
      payload: InterfaceMap[Key][];
    }
  | {
      kind: WeakEntityRepositoryUpdateKind.DELETE;
      payload: (number | string)[];
    }
);

type WeakEntityRepositoryStore<InterfaceMap extends Record<string, object>> =
  FinalizationWeakMap<
    WeakEntityRepositoryItemKey<keyof InterfaceMap & string>,
    WeakEntityRepositoryItem<InterfaceMap, keyof InterfaceMap>
  >;
export type WindowExtensionTag<
  InterfaceMap extends Record<string, object>,
  Tag extends string = '',
> = {
  [k in `__WEAK_CACHE_${Tag}`]: WeakEntityRepositoryStore<InterfaceMap>;
};

interface WSItem {
  id: number | string;
  updatedAt: Date;
}

export class WeakEntityRepository<
  InterfaceMap extends Record<string, object>,
  WSSupportName extends string,
  ParamsMap extends Record<string, object>,
  Config extends EntityConfig<
    InterfaceMap,
    WSSupportName,
    ParamsMap
  > = EntityConfig<InterfaceMap, WSSupportName, ParamsMap>,
  WSService extends CustomEventEmitter<
    SocketServiceEventMap<WSSupportName>,
    SocketServiceEventMapListeners<WSSupportName>
  > = CustomEventEmitter<
    SocketServiceEventMap<WSSupportName>,
    SocketServiceEventMapListeners<WSSupportName>
  >,
> extends CustomEventEmitter<{
  [WeakEntityRepositoryEventType.RESET]: {};
  [WeakEntityRepositoryEventType.UPDATE]: UpdateEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.BATCH_UPDATE]: BatchedUpdateEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.QUERY_CREATE]: QueryEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.QUERY_FETCH]: QueryEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS]: QueryEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.QUERY_FETCH_ERROR]: QueryEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.QUERY_FETCH_FINISH]: QueryEvent<InterfaceMap>;
  [WeakEntityRepositoryEventType.QUERY_FINALIZE]: QueryEmptyEvent<InterfaceMap>;
}> {
  readonly #store: WeakEntityRepositoryStore<Config>;

  readonly #config: Config;

  readonly #ws: WSService;

  readonly #wsListeners: Map<
    keyof InterfaceMap,
    {
      unsubscribe: Unsubscribe;
      batchUnsubscribe?: Unsubscribe;
    }
  >;

  readonly #entityTriggers: Map<keyof InterfaceMap, Unsubscribe>;

  readonly #reactiveConstructor: ReactiveConstructor = (obj) => obj;

  static #getEventTypeFromWSMessage(
    eventName: string,
  ): WeakEntityRepositoryUpdateKind {
    if (eventName.endsWith('Created')) {
      return WeakEntityRepositoryUpdateKind.CREATE;
    }
    if (eventName.endsWith('Updated')) {
      return WeakEntityRepositoryUpdateKind.UPDATE;
    }
    if (eventName.endsWith('Deleted')) {
      return WeakEntityRepositoryUpdateKind.DELETE;
    }
  }

  #getEntityConfig<Key extends keyof InterfaceMap>(
    entity: Key,
  ): EntityConfigRecord<InterfaceMap, Key, WSSupportName, ParamsMap> {
    // TS can't find relation between Config and InterfaceMap, so casting
    return this.#config[entity] as unknown as EntityConfigRecord<
      InterfaceMap,
      Key,
      WSSupportName,
      ParamsMap
    >;
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  #getEntityKey<Key extends keyof InterfaceMap>(
    entity: Key,
    where: Where<InterfaceMap[Key]>,
  ): WeakEntityRepositoryItemKey<keyof InterfaceMap & string> {
    return `${
      entity as keyof InterfaceMap & string
    }${KeySeparator}${JSON.stringify(where)}`;
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  #parseEntityKey<
    Key extends keyof InterfaceMap & string = keyof InterfaceMap & string,
    K extends WeakEntityRepositoryItemKey<Key> = WeakEntityRepositoryItemKey<Key>,
  >(key: K): [Key, Where<InterfaceMap[Key]>] {
    return [
      key.split(KeySeparator, 1)[0] as Key,
      JSON.parse(key.slice(key.indexOf(KeySeparator) + KeySeparator.length)),
    ];
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  #getEntityFilter<Key extends keyof InterfaceMap>(
    entity: Key,
    filter: Where<InterfaceMap[Key]>,
  ): EntityFilter<InterfaceMap[Key]> {
    return createFilterFromWhereObject(entity, filter);
  }

  // eslint-disable-next-line class-methods-use-this
  #sync() {
    // Update Vuex or any other state management system
  }

  #createEntityCollection<Key extends keyof InterfaceMap>(
    entity: Key,
    where: Where<InterfaceMap[Key]>,
    params: Key extends keyof ParamsMap ? ParamsMap[Key] : never = null,
  ) {
    const defaults = {
      entity,
      isLoading: true,
      isLoaded: false,
      isFetching: false,
      promise: null,
      error: null,
      _parent: null,
    };
    const newItem = this.#reactiveConstructor<
      WeakEntityRepositoryItem<InterfaceMap, Key>
    >({
      ...defaults,
      data: [],
      refetch: () => {
        newItem.isLoading = true;
        newItem.isFetching = true;

        this._dispatchEvent(WeakEntityRepositoryEventType.QUERY_FETCH, {
          entityName: entity,
          where,
          response: newItem,
        });

        if (newItem.promise) {
          newItem.promise.cancel();
        }
        const config = this.#getEntityConfig(entity);
        const onFinally = () => {
          newItem.isLoading = false;
          newItem.isLoaded = true;
          newItem.isFetching = false;
          newItem._parent = null;

          this.#sync();
          this._dispatchEvent(
            WeakEntityRepositoryEventType.QUERY_FETCH_FINISH,
            {
              entityName: entity,
              where,
              response: newItem,
            },
          );
        };
        newItem.promise = makeCancellable(
          config.fetcher(params, where, config.orderBy ?? []).then(
            (data: InterfaceMap[Key][]) => {
              newItem.data = data;

              this._dispatchEvent(
                WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
                {
                  entityName: entity,
                  where,
                  response: newItem,
                },
              );
              onFinally();
              return data;
            },
            (error: Error) => {
              newItem.error = error;

              this._dispatchEvent(
                WeakEntityRepositoryEventType.QUERY_FETCH_ERROR,
                {
                  entityName: entity,
                  where,
                  response: newItem,
                },
              );
              onFinally();
              throw error;
            },
          ),
        );
        return newItem.promise;
      },
      _filter: this.#getEntityFilter(entity, where),
      _reset: () => {
        if (newItem.promise) newItem.promise.cancel();
        Object.assign(newItem, defaults, { data: [] });
        newItem.refetch();
      },
    });
    this.#store.set(this.#getEntityKey(entity, where), newItem);
    return newItem;
  }

  #getEntityCollection<Key extends keyof InterfaceMap>(
    entity: Key,
    where: Where<InterfaceMap[Key]>,
  ): WeakEntityRepositoryItem<InterfaceMap, Key> | undefined {
    // TS can't know that the key should match the type of the item, so we cast it (Downcast)
    return this.#store.get(
      this.#getEntityKey<Key>(entity, where),
    ) as unknown as WeakEntityRepositoryItem<InterfaceMap, Key> | undefined;
  }

  #getEntityCollections<Key extends keyof InterfaceMap & string>(
    entity: Key,
  ): {
    entity: Key;
    where: Where<InterfaceMap[Key]>;
    response: WeakEntityRepositoryItem<InterfaceMap, Key>;
    key: WeakEntityRepositoryItemKey<Key>;
  }[] {
    return [...this.#store.entries()]
      .filter(([key]) => key.startsWith(`${entity}${KeySeparator}`))
      .map(([key, item]) => {
        const [entity, where] = this.#parseEntityKey<Key>(
          key as WeakEntityRepositoryItemKey<Key>,
        );
        return {
          entity,
          where,
          key: key as WeakEntityRepositoryItemKey<Key>,
          response: item as unknown as WeakEntityRepositoryItem<
            InterfaceMap,
            Key
          >,
        };
      });
  }

  static #mutateEntityArray<T extends WSItem>(
    entity: T,
    data: T[],
    filter: EntityFilter<T>,
    orderBy: OrderBy<T>,
  ) {
    // Because the values of an entity might have changed,
    // we need to check the filters to see if
    // the entity should still exist in this collection.
    const oldIndex = data.findIndex((e) => e.id === entity.id);
    const previouslyMatched = oldIndex > -1;
    const nowMatches = filter(entity);

    if (!previouslyMatched && nowMatches) {
      // Previously didn't match, but now does
      // Add the new then resort
      // Sort and splice affect the data array directly, no shallow copies
      data.splice(data.length, 0, entity);
      data.sort((a, b) => compareEntities(a, b, orderBy));
    } else if (previouslyMatched && nowMatches) {
      // Previously matched, still matches

      // To avoid issues where websocket messages arrive out-of-order,
      // check that the old version's 'updatedAt' value of the entity
      // isn't newer than the incoming one.
      const oldEntity = data[oldIndex];
      if (
        'updatedAt' in oldEntity &&
        'updatedAt' in entity &&
        oldEntity.updatedAt >= entity.updatedAt
      ) {
        return;
      }
      // Remove the old, add the new then resort
      // Sort and splice affect the data array directly, no shallow copies
      data.splice(oldIndex, 1);
      data.splice(oldIndex, 0, entity);
      data.sort((a, b) => compareEntities(a, b, orderBy));
    } else if (previouslyMatched && !nowMatches) {
      // Previously matched, now doesn't
      data.splice(oldIndex, 1);
    } else {
      // Previously didn't match, still doesn't - do nothing
    }
  }

  #upsertEntity<Key extends WSSupportName>(
    entityName: Key,
    entity: InterfaceMap[Key],
  ) {
    const { orderBy = [] } = this.#getEntityConfig(entityName);
    this.#getEntityCollections(entityName).forEach(
      ({ response: { data, _filter } }) =>
        WeakEntityRepository.#mutateEntityArray(
          entity as WSItem,
          data as WSItem[],
          _filter as EntityFilter<WSItem>,
          orderBy as OrderBy<WSItem>,
        ),
    );
    this.#sync();
  }

  #batchedUpsertEntities<Key extends WSSupportName>(
    entityName: Key,
    entities: InterfaceMap[Key][],
  ) {
    const { orderBy = [] } = this.#getEntityConfig(entityName);
    this.#getEntityCollections(entityName).forEach(
      ({ response: { data, _filter } }) => {
        entities.forEach((entity) => {
          WeakEntityRepository.#mutateEntityArray(
            entity as WSItem,
            data as WSItem[],
            _filter as EntityFilter<WSItem>,
            orderBy as OrderBy<WSItem>,
          );
        });
      },
    );
    this.#sync();
  }

  #removeEntity(entityName: WSSupportName, id: number | string) {
    this.#getEntityCollections(entityName).forEach(({ response: { data } }) => {
      const index = data.findIndex((e) => (e as WSItem).id === id);
      if (index !== -1) data.splice(index, 1);
    });

    this.#sync();
  }

  #batchedRemoveEntities(entityName: WSSupportName, ids: (number | string)[]) {
    this.#getEntityCollections(entityName).forEach(({ response }) => {
      response.data = response.data.filter(
        (e) => !ids.includes((e as WSItem).id),
      );
    });

    this.#sync();
  }

  #registerWebsocketHandler<Key extends keyof InterfaceMap & WSSupportName>(
    entity: Key,
  ) {
    if (this.#wsListeners.has(entity)) return;
    const { batchFetcher } = this.#getEntityConfig(entity);

    this.#wsListeners.set(entity, {
      unsubscribe: this.#ws.addEventListener(
        entityEventType(entity),
        ({ kind: eventKind, data }) =>
          this.dispatchUpdate(
            WeakEntityRepository.#getEventTypeFromWSMessage(eventKind),
            entity,
            data,
          ),
      ),
      ...(!!batchFetcher && {
        batchUnsubscribe: this.#ws.addEventListener(
          batchEntityEventType(entity),
          ({ kind: eventKind, data }) =>
            this.dispatchBatchedUpdate(
              WeakEntityRepository.#getEventTypeFromWSMessage(eventKind),
              entity,
              data as number[],
            ),
        ),
      }),
    });
  }

  #shouldApplyWebsocket<Key extends keyof InterfaceMap>(
    entity: Key,
    // @ts-expect-error:TS2677
    // In realtimeTypes.ts we defined
    // that useWebsocket should be false for non-list entities,
    // so we sure entity is keyof Config & ListEventEntitiesResponseDataEnum
  ): entity is keyof Config & WSSupportName {
    return this.#getEntityConfig(entity).useWebsocket !== false;
  }

  #registerTriggers<Key extends keyof InterfaceMap & string>(entity: Key) {
    if (this.#entityTriggers.has(entity)) return;

    const { triggers } = this.#getEntityConfig(entity);
    if (!triggers) return;

    const debouncedRefetch = debounce(() => {
      this.#getEntityCollections(entity).forEach(({ response }) =>
        response.refetch(),
      );
    }, 1.5 * 1000);
    const refetch = () => {
      this.#getEntityCollections(entity).forEach(({ response }) => {
        response.isFetching = true;
      });
      debouncedRefetch();
    };

    const socketUpdateHandler = ({ entity }: EntityEvent<WSSupportName>) => {
      if (triggers.includes(entity)) {
        refetch();
      }
    };

    const socketBatchUpdateHandler = ({
      entity,
    }: BatchEntityEvent<WSSupportName>) => {
      if (triggers.includes(entity)) {
        refetch();
      }
    };

    const queryFinalizeHandler = ({
      entityName,
    }: QueryEmptyEvent<InterfaceMap>) => {
      if (
        entityName !== entity ||
        this.#getEntityCollections(entity).length > 0
      ) {
        return;
      }

      this.#ws.removeEventListener(
        SocketServiceEventType.ENTITY,
        socketUpdateHandler,
      );
      this.#ws.removeEventListener(
        SocketServiceEventType.BATCH_ENTITY,
        socketBatchUpdateHandler,
      );

      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FINALIZE,
        queryFinalizeHandler,
      );
      this.addEventListener(
        WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
        // eslint-disable-next-line no-use-before-define
        queryFetchHandler,
      );
    };

    const queryFetchHandler = ({ entityName }: QueryEvent<InterfaceMap>) => {
      if (entityName !== entity) return;

      this.#ws.addEventListener(
        SocketServiceEventType.ENTITY,
        socketUpdateHandler,
      );
      this.#ws.addEventListener(
        SocketServiceEventType.BATCH_ENTITY,
        socketBatchUpdateHandler,
      );

      this.addEventListener(
        WeakEntityRepositoryEventType.QUERY_FINALIZE,
        queryFinalizeHandler,
      );
      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
        queryFetchHandler,
      );
    };

    this.addEventListener(
      WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
      queryFetchHandler,
    );

    this.#entityTriggers.set(entity, () => {
      this.#ws.removeEventListener(
        SocketServiceEventType.ENTITY,
        socketUpdateHandler,
      );
      this.#ws.removeEventListener(
        SocketServiceEventType.BATCH_ENTITY,
        socketBatchUpdateHandler,
      );

      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FINALIZE,
        queryFinalizeHandler,
      );
      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
        queryFetchHandler,
      );
    });
  }

  #shouldApplyTriggers<Key extends keyof InterfaceMap>(entity: Key): boolean {
    return this.#getEntityConfig(entity).triggers?.length > 0;
  }

  #getPopulatingSource<Key extends keyof InterfaceMap & string>(
    entity: Key,
    where: Where<InterfaceMap[Key]> = {},
  ): WeakEntityRepositoryItem<InterfaceMap, Key> | null {
    const { populateStrategy = PopulateStrategy.Subset } =
      this.#getEntityConfig(entity);

    if (populateStrategy === PopulateStrategy.Disable) return null;

    if (!Object.keys(where ?? {}).length) return;

    if (populateStrategy === PopulateStrategy.Complete) {
      return this.#getEntityCollection(entity, {}) ?? null;
    }

    if (populateStrategy === PopulateStrategy.Subset) {
      return (
        this.#getEntityCollections(entity).find(({ where: sourceWhere }) =>
          isSubset(sourceWhere, where),
        )?.response ?? null
      );
    }
  }

  constructor(
    config: Config,
    wsService: WSService,
    reactiveConstructor?: ReactiveConstructor | null,
    tag?: string,
  ) {
    super();

    this.#config = config;

    this.#ws = wsService;
    if (reactiveConstructor) {
      this.#reactiveConstructor = reactiveConstructor;
    }
    this.#wsListeners = new Map();
    this.#entityTriggers = new Map();

    this.#store = new FinalizationWeakMap();
    this.#store.addEventListener(
      FinalizationWeakMapEventType.FINALIZE,
      ({ key }) => {
        if (isLocalEnv) {
          // eslint-disable-next-line no-console
          console.debug(
            `WeakEntityRepository(${tag}): Cache cleared for ${key}`,
          );
        }

        this.#sync();
        const [entityName, where] = this.#parseEntityKey(key);
        this._dispatchEvent(WeakEntityRepositoryEventType.QUERY_FINALIZE, {
          entityName,
          where,
        });
      },
    );
    window[`__WEAK_CACHE_${tag ?? ''}`] = this.#store;
  }

  softReset() {
    this.#store.forEach((c) => c._reset());

    this.#sync();
    this._dispatchEvent(WeakEntityRepositoryEventType.RESET, {});
  }

  detachedReset() {
    // Cancel all outstanding promises
    this.#store.forEach((c) => c.promise?.cancel());
    this.#store.clear();

    this.#wsListeners.forEach((l) => {
      l.unsubscribe();
      l.batchUnsubscribe?.();
    });
    this.#wsListeners.clear();

    this.#entityTriggers.forEach((t) => t());
    this.#entityTriggers.clear();

    this.#sync();
    this._dispatchEvent(WeakEntityRepositoryEventType.RESET, {});
  }

  dispatchUpdate<K extends keyof InterfaceMap & WSSupportName>(
    kind: WeakEntityRepositoryUpdateKind,
    entity: K,
    data: unknown,
  ): void {
    if (kind === WeakEntityRepositoryUpdateKind.DELETE) {
      const id =
        typeof data === 'object'
          ? (data as WSItem).id
          : (data as number | string);
      this.#removeEntity(entity, id);
      this._dispatchEvent(WeakEntityRepositoryEventType.UPDATE, {
        entityName: entity,
        kind,
        payload: id,
      });
    } else if (
      // After deleting an entity if the websocket message order isn't correct,
      // we get the entity id on an 'updated' event
      // E.g., deleting an employee
      typeof data === 'object' &&
      [
        WeakEntityRepositoryUpdateKind.CREATE,
        WeakEntityRepositoryUpdateKind.UPDATE,
      ].includes(kind)
    ) {
      const entityInstance = this.#getEntityConfig(entity).entityMaker(data);
      this.#upsertEntity(entity, entityInstance);
      this._dispatchEvent(WeakEntityRepositoryEventType.UPDATE, {
        entityName: entity,
        kind,
        payload: entityInstance,
      });
    }
  }

  async dispatchBatchedUpdate<Key extends WSSupportName>(
    kind: WeakEntityRepositoryUpdateKind,
    entity: Key,
    data: number[] | InterfaceMap[Key][],
  ): Promise<void> {
    if (data.length === 0) return;

    const isEntityInstance = typeof data[0] === 'object';

    if (kind === WeakEntityRepositoryUpdateKind.DELETE) {
      const ids: (number | string)[] = isEntityInstance
        ? (data as WSItem[]).map((e) => e.id)
        : (data as (number | string)[]);

      this.#batchedRemoveEntities(entity, ids);
      this._dispatchEvent(WeakEntityRepositoryEventType.BATCH_UPDATE, {
        entityName: entity,
        kind,
        payload: ids,
      });
    } else if (
      // After deleting an entity if the websocket message order isn't correct,
      // we get the entity id on an 'updated' event
      // E.g., deleting an employee
      typeof data === 'object' &&
      [
        WeakEntityRepositoryUpdateKind.CREATE,
        WeakEntityRepositoryUpdateKind.UPDATE,
      ].includes(kind)
    ) {
      const entities: InterfaceMap[Key][] = isEntityInstance
        ? (data as InterfaceMap[Key][])
        : ((await this.#getEntityConfig(entity).batchFetcher(
            data as (number | string)[],
          )) as InterfaceMap[Key][]);
      this.#batchedUpsertEntities(entity, entities);
      this._dispatchEvent(WeakEntityRepositoryEventType.BATCH_UPDATE, {
        entityName: entity,
        kind,
        payload: entities,
      });
    }
  }

  subscribe<Key extends keyof InterfaceMap & string>(
    entity: Key,
    where: Where<InterfaceMap[Key]> = {},
    params: Key extends keyof ParamsMap ? ParamsMap[Key] : never = null,
  ): WeakEntityRepositoryResponse<InterfaceMap, Key> {
    where = objectDeepSort(where ?? {});

    const collection = this.#getEntityCollection(entity, where);
    if (collection) return collection;

    let newItem: WeakEntityRepositoryItem<InterfaceMap, Key> = null;

    // Trying to populate the collection from existing sources
    const populatingSource = this.#getPopulatingSource(entity, where);
    if (populatingSource) {
      newItem = this.#createEntityCollection(entity, where, params);
      newItem._parent = new WeakRef(populatingSource);
      newItem.data = populatingSource.data.filter(newItem._filter);
      newItem.isLoading = populatingSource.isLoading;
      newItem.isLoaded = populatingSource.isLoaded;

      if (populatingSource.promise) {
        // We are not assigning a new promise to newItem.promise
        // because cancelable promises has propagation issues currently unimportant to resolve.
        // Only subscribing to its result
        populatingSource.promise.then((data) => {
          newItem.data = data.filter(newItem._filter);
          newItem.isLoading = false;
          newItem.isLoaded = true;
        });
      }
    }

    if (!newItem) {
      newItem = this.#createEntityCollection(entity, where, params);
      newItem.refetch();
    }

    if (this.#shouldApplyWebsocket(entity)) {
      this.#registerWebsocketHandler(entity);
    }
    if (this.#shouldApplyTriggers(entity)) {
      this.#registerTriggers(entity);
    }

    this._dispatchEvent(WeakEntityRepositoryEventType.QUERY_CREATE, {
      entityName: entity,
      where,
      response: newItem,
    });
    return newItem;
  }
}
