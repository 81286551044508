/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftTemplateAreaSessionsData
 */
export interface ShiftTemplateAreaSessionsData {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSessionsData
     */
    shiftAreaId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSessionsData
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSessionsData
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSessionsData
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateAreaSessionsData
     */
    endMinute: number;
}

/**
 * Check if a given object implements the ShiftTemplateAreaSessionsData interface.
 */
export function instanceOfShiftTemplateAreaSessionsData(value: object): boolean {
    if (!('shiftAreaId' in value)) return false;
    if (!('startHour' in value)) return false;
    if (!('startMinute' in value)) return false;
    if (!('endHour' in value)) return false;
    if (!('endMinute' in value)) return false;
    return true;
}

export function ShiftTemplateAreaSessionsDataFromJSON(json: any): ShiftTemplateAreaSessionsData {
    return ShiftTemplateAreaSessionsDataFromJSONTyped(json, false);
}

export function ShiftTemplateAreaSessionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplateAreaSessionsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftAreaId': json['shiftAreaId'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
    };
}

export function ShiftTemplateAreaSessionsDataToJSON(value?: ShiftTemplateAreaSessionsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftAreaId': value['shiftAreaId'],
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
    };
}

