/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
} from './Employee';
import type { EmployeeWithAvailabilityAvailablePeriod } from './EmployeeWithAvailabilityAvailablePeriod';
import {
    EmployeeWithAvailabilityAvailablePeriodFromJSON,
    EmployeeWithAvailabilityAvailablePeriodFromJSONTyped,
    EmployeeWithAvailabilityAvailablePeriodToJSON,
} from './EmployeeWithAvailabilityAvailablePeriod';

/**
 * Represents a User working for a Company, with a collection of date ranges representing their availability
 * @export
 * @interface EmployeeWithAvailability
 */
export interface EmployeeWithAvailability {
    /**
     * 
     * @type {Employee}
     * @memberof EmployeeWithAvailability
     */
    employee?: Employee;
    /**
     * 
     * @type {Array<EmployeeWithAvailabilityAvailablePeriod>}
     * @memberof EmployeeWithAvailability
     */
    availablePeriods?: Array<EmployeeWithAvailabilityAvailablePeriod>;
}

/**
 * Check if a given object implements the EmployeeWithAvailability interface.
 */
export function instanceOfEmployeeWithAvailability(value: object): boolean {
    return true;
}

export function EmployeeWithAvailabilityFromJSON(json: any): EmployeeWithAvailability {
    return EmployeeWithAvailabilityFromJSONTyped(json, false);
}

export function EmployeeWithAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeWithAvailability {
    if (json == null) {
        return json;
    }
    return {
        
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'availablePeriods': json['availablePeriods'] == null ? undefined : ((json['availablePeriods'] as Array<any>).map(EmployeeWithAvailabilityAvailablePeriodFromJSON)),
    };
}

export function EmployeeWithAvailabilityToJSON(value?: EmployeeWithAvailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employee': EmployeeToJSON(value['employee']),
        'availablePeriods': value['availablePeriods'] == null ? undefined : ((value['availablePeriods'] as Array<any>).map(EmployeeWithAvailabilityAvailablePeriodToJSON)),
    };
}

