<template>
  <fieldset>
    <label
      v-for="(toggle, index) in items"
      :key="index"
      :class="{
        checked: toggle.value === selected,
        disabled: toggle.disabled,
      }"
    >
      <input
        type="radio"
        name="viewMode"
        :value="toggle.value"
        :checked="toggle.value === selected"
        @input="toggle.disabled ? null : (selected = toggle)"
      />
      <span class="label">
        {{ toggle.label }}
      </span>
      <span
        v-if="toggle.stat"
        class="rounded-lg px-2 ml-2 leading-tight"
        :class="
          toggle.value === selected
            ? 'bg-white text-purple-300'
            : 'bg-purple-300 text-white'
        "
      >
        {{ toggle.stat }}
      </span>
    </label>
  </fieldset>
</template>

<script>
/**
 * New version of MobileHeaderRadio
 * This component does not require use of vuex store
 * See Schedule.vue for setup
 */
export default {
  name: 'MobileHeaderTabs',
  props: {
    /**
     * {
     *   disabled: boolean,
     *   value: any,
     *   label: string,
     *   action: Function,
     * }
     */
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: this.items.find((i) => i.checked)?.value,
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(item) {
        item.action();
        this.value = item.value;
      },
    },
  },
  watch: {
    items: {
      deep: true,
      handler(items) {
        this.value = items.find((i) => i.checked).value;
      },
    },
    '$route.query.tab': {
      handler(t) {
        if (t && t !== this.selected) {
          this.value = t;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
fieldset {
  display: flex;
  background: $gray-50;
  padding: 2px;
  flex-direction: row;
  width: 100%;

  label {
    @apply font-bold;
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    color: $purple-900;
    font-size: $text-12;
    width: 100%;
    min-width: 100px;

    &.checked {
      background: $purple-300;
      color: $white;

      span {
        &.total {
          background: $purple;
          color: #fff;
        }
      }
    }

    &.disabled {
      flex-direction: column;

      span {
        opacity: 0.5;
      }
    }

    input {
      position: absolute;
      visibility: hidden;
    }

    span {
      &.total {
        background: #fff;
        color: $purple;
      }

      &.label {
        margin: 0.75rem 0;
        text-transform: capitalize;
      }

      &.no-shifts {
        margin-bottom: 0.25rem;
      }
    }
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    margin-right: 0.5rem;
    color: $purple;
    font-size: $text-12;
  }
}
</style>
