/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscription } from './BillingSubscription';
import {
    BillingSubscriptionFromJSON,
    BillingSubscriptionFromJSONTyped,
    BillingSubscriptionToJSON,
} from './BillingSubscription';

/**
 * 
 * @export
 * @interface ShowLatestSubscriptionResponse
 */
export interface ShowLatestSubscriptionResponse {
    /**
     * 
     * @type {BillingSubscription}
     * @memberof ShowLatestSubscriptionResponse
     */
    data?: BillingSubscription;
}

/**
 * Check if a given object implements the ShowLatestSubscriptionResponse interface.
 */
export function instanceOfShowLatestSubscriptionResponse(value: object): boolean {
    return true;
}

export function ShowLatestSubscriptionResponseFromJSON(json: any): ShowLatestSubscriptionResponse {
    return ShowLatestSubscriptionResponseFromJSONTyped(json, false);
}

export function ShowLatestSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLatestSubscriptionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : BillingSubscriptionFromJSON(json['data']),
    };
}

export function ShowLatestSubscriptionResponseToJSON(value?: ShowLatestSubscriptionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': BillingSubscriptionToJSON(value['data']),
    };
}

