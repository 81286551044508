import { IconType } from '@/lib/enum/Icon';
import { getEntity } from '@/util/entityFunctions';

export type FilterItemArgumentType = string | number | boolean;

export type FilterItem<
  T extends FilterItemArgumentType = FilterItemArgumentType,
> = {
  value: T;
  label: string;
  field: string;
  icon?: IconType;
};

export const toFilterItem = <T extends FilterItemArgumentType>(
  value: T,
  label: string,
  field: string,
  icon?: IconType,
): FilterItem<T> => ({ value, label, field, icon });

export const getFilterValues = <T extends FilterItemArgumentType>(
  items: FilterItem<T>[],
): T[] => items.map((i) => i.value);

// When a filter input returns data, it returns ids only so this needs to be converted to a FilterItem
export const getFilterResult = <T extends FilterItemArgumentType>(
  values: T[],
  items: { id: T; name?: string; fullName?: string }[],
  field: string,
  icon?: IconType,
): FilterItem<T>[] =>
  items
    .filter((i) => values.includes(i.id))
    .map((i) => toFilterItem(i.id, i.name ?? i.fullName ?? '', field, icon));

export type FilterKey = string;
export enum FilterConfigItemTypeEnum {
  Autocomplete = 'Autocomplete',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
}
export type FilterOption = {
  id: number | string | boolean | null;
  name: string;
};
export type FilterConfigItem = {
  hidden?: boolean; // Won't be visible in filters but will be updated on reset
  ignore?: boolean; // Will be totally ignored from processing

  icon?: IconType;
  label?: string;

  default?: FilterItemArgumentType | FilterItemArgumentType[] | null;

  type: FilterConfigItemTypeEnum; // Default: autocomplete

  onAfterChange?: (
    value: FilterItemArgumentType | FilterItemArgumentType[] | null,
  ) => void;
} & (
  | {
      type: FilterConfigItemTypeEnum.Autocomplete;
      options: FilterOption[];
      multiple?: boolean; // If true will expect an array in value field
    }
  | {
      type: FilterConfigItemTypeEnum.Radio;
      options: FilterOption[];
    }
  | {
      type: FilterConfigItemTypeEnum.Checkbox;
    }
);
export type FilterValue<T extends FilterKey = FilterKey> = Record<
  T,
  FilterItemArgumentType | FilterItemArgumentType[] | null
>;
export type FilterConfig<T extends FilterKey = FilterKey> = Record<
  T,
  FilterConfigItem
>;

export const getFilterProcessableFields = <T extends FilterKey = FilterKey>(
  config: FilterConfig<T>,
): T[] =>
  Object.entries<FilterConfigItem>(config)
    .filter(([, v]) => !v.ignore)
    .map((c) => c[0] as T);

const isFilterValueSet = (
  props: FilterConfigItem,
  v: FilterItemArgumentType | FilterItemArgumentType[] | null,
): boolean => {
  if (v == null) return false;

  if (props.type === FilterConfigItemTypeEnum.Checkbox) {
    return props.default === undefined
      ? v !== false && v != null
      : v !== props.default;
  }

  if (props.type === FilterConfigItemTypeEnum.Radio) {
    return props.default === undefined || v !== props.default;
  }

  if (props.type === FilterConfigItemTypeEnum.Autocomplete) {
    return props.multiple ? !!(v as []).length : !!v;
  }

  throw new Error(`Invalid filter: ${JSON.stringify(props)}`);
};

export const getFilterSetValues = <T extends FilterKey = FilterKey>(
  config: FilterConfig<T>,
  value: FilterValue<T>,
): T[] =>
  getFilterProcessableFields(config).filter((k) =>
    isFilterValueSet(config[k], value[k]),
  );

const getFilterItemLabel = (
  props: FilterConfigItem,
  value: FilterItemArgumentType,
) => {
  return props.type === FilterConfigItemTypeEnum.Checkbox
    ? props.label
    : getEntity(value as string | number, props.options).name;
};

export const getFilterItems = <T extends FilterKey = FilterKey>(
  config: FilterConfig<T>,
  value: FilterValue<T>,
): FilterItem[] =>
  getFilterSetValues(config, value).flatMap((k) => {
    const props = config[k];
    const v = value[k];

    if (
      props.type === FilterConfigItemTypeEnum.Autocomplete &&
      props.multiple
    ) {
      const options: FilterOption['id'][] | null = props.options.map(
        ({ id }) => id,
      );
      return (v as FilterItemArgumentType[])
        .filter((i) => options.includes(i)) // in some cases config options might be changed earlier than value
        .map((i) =>
          toFilterItem(i, getFilterItemLabel(props, i), k, props.icon),
        );
    }

    return toFilterItem(
      v as FilterItemArgumentType,
      getFilterItemLabel(props, v as FilterItemArgumentType),
      k,
      props.icon,
    );
  });

export const getResetFilters = <T extends FilterKey = FilterKey>(
  config: FilterConfig<T>,
  value: FilterValue<T>,
): FilterValue<T> =>
  getFilterProcessableFields(config).reduce((acc, k) => {
    const props = config[k];
    if (props.default !== undefined) return { ...acc, [k]: props.default };

    if (props.type === FilterConfigItemTypeEnum.Checkbox) {
      return { ...acc, [k]: false };
    }

    if (props.type === FilterConfigItemTypeEnum.Radio) {
      return { ...acc, [k]: null };
    }

    if (props.type === FilterConfigItemTypeEnum.Autocomplete) {
      return {
        ...acc,
        [k]: props.multiple ? [] : null,
      };
    }

    throw new Error(`Invalid filter: ${JSON.stringify(props)}`);
  }, value);

export const getFiltersWithoutItem = <T extends FilterKey = FilterKey>(
  config: FilterConfig<T>,
  value: FilterValue<T>,
  item: FilterItem,
): FilterValue<T> => {
  const key = item.field;
  const v = item.value;
  const props = config[key];

  if (props.type === FilterConfigItemTypeEnum.Checkbox) {
    return { ...value, [key]: props.default ? props.default : false };
  }

  if (props.type === FilterConfigItemTypeEnum.Radio) {
    return { ...value, [key]: props.default ? props.default : null };
  }

  if (props.type === FilterConfigItemTypeEnum.Autocomplete) {
    return props.multiple
      ? {
          ...value,
          [key]: (value[key] as FilterItemArgumentType[]).filter(
            (id) => id !== v,
          ),
        }
      : { ...value, [key]: props.default ? props.default : null };
  }

  throw new Error(`Invalid filter: ${JSON.stringify(props)}`);
};
