var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-nowrap"},[_c('label',{staticClass:"label cursor-pointer",class:{
      checked: _vm.isChecked,
      large: _vm.isLarge,
      disabled: _vm.disabled,
      noLabel: _vm.noLabel,
      'all:p-3 bg-gray-200 rounded border-1 border-gray-300 w-full': _vm.inset,
    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],staticClass:"checkbox",attrs:{"disabled":_vm.loading || _vm.disabled,"type":"checkbox"},domProps:{"value":_vm.checkedValue,"checked":Array.isArray(_vm.inputVal)?_vm._i(_vm.inputVal,_vm.checkedValue)>-1:(_vm.inputVal)},on:{"change":function($event){var $$a=_vm.inputVal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.checkedValue,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputVal=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputVal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputVal=$$c}}}}),_vm._t("default",function(){return [(!_vm.noLabel && _vm.labelText)?_c('span',{staticClass:"label-text text-16"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]):_vm._e()]}),(_vm.required)?_c('span',{staticClass:"required",domProps:{"innerHTML":_vm._s('&#42;')}}):_vm._e()],2),(_vm.tooltip)?_c('ToolTipIcon',{staticClass:"inline-block",attrs:{"message":_vm.$tc(`tooltip.${_vm.tooltip}`)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }