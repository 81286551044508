/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
} from './JobRole';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { ShiftTemplateBreak } from './ShiftTemplateBreak';
import {
    ShiftTemplateBreakFromJSON,
    ShiftTemplateBreakFromJSONTyped,
    ShiftTemplateBreakToJSON,
} from './ShiftTemplateBreak';
import type { ShiftTemplateShiftAreaSession } from './ShiftTemplateShiftAreaSession';
import {
    ShiftTemplateShiftAreaSessionFromJSON,
    ShiftTemplateShiftAreaSessionFromJSONTyped,
    ShiftTemplateShiftAreaSessionToJSON,
} from './ShiftTemplateShiftAreaSession';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * A saved template that can be used to quickly generate new shifts that share the same settings
 * @export
 * @interface ShiftTemplate
 */
export interface ShiftTemplate {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    companyId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShiftTemplate
     */
    tagIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    locationId: number | null;
    /**
     * 
     * @type {Location}
     * @memberof ShiftTemplate
     */
    locations?: Location;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    jobRoleId: number | null;
    /**
     * 
     * @type {JobRole}
     * @memberof ShiftTemplate
     */
    jobRole?: JobRole;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    endMinute: number;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof ShiftTemplate
     */
    showEndTime: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof ShiftTemplate
     */
    showJobRole: boolean;
    /**
     * 
     * @type {Array<ShiftTemplateShiftAreaSession>}
     * @memberof ShiftTemplate
     */
    shiftTemplateAreaSessions: Array<ShiftTemplateShiftAreaSession>;
    /**
     * 
     * @type {Array<ShiftTemplateBreak>}
     * @memberof ShiftTemplate
     */
    shiftTemplateBreaks: Array<ShiftTemplateBreak>;
    /**
     * The amount of paid break included in this shift (in minutes)
     * @type {number}
     * @memberof ShiftTemplate
     */
    paidBreakInMinutes: number;
    /**
     * The amount of unpaid break included in this shift (in minutes)
     * @type {number}
     * @memberof ShiftTemplate
     */
    unpaidBreakInMinutes: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    notes: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ShiftTemplate
     */
    tags?: Array<Tag>;
}

/**
 * Check if a given object implements the ShiftTemplate interface.
 */
export function instanceOfShiftTemplate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('tagIds' in value)) return false;
    if (!('name' in value)) return false;
    if (!('locationId' in value)) return false;
    if (!('jobRoleId' in value)) return false;
    if (!('startHour' in value)) return false;
    if (!('startMinute' in value)) return false;
    if (!('endHour' in value)) return false;
    if (!('endMinute' in value)) return false;
    if (!('showEndTime' in value)) return false;
    if (!('showJobRole' in value)) return false;
    if (!('shiftTemplateAreaSessions' in value)) return false;
    if (!('shiftTemplateBreaks' in value)) return false;
    if (!('paidBreakInMinutes' in value)) return false;
    if (!('unpaidBreakInMinutes' in value)) return false;
    if (!('notes' in value)) return false;
    return true;
}

export function ShiftTemplateFromJSON(json: any): ShiftTemplate {
    return ShiftTemplateFromJSONTyped(json, false);
}

export function ShiftTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'tagIds': json['tagIds'],
        'name': json['name'],
        'locationId': json['locationId'],
        'locations': json['locations'] == null ? undefined : LocationFromJSON(json['locations']),
        'jobRoleId': json['jobRoleId'],
        'jobRole': json['jobRole'] == null ? undefined : JobRoleFromJSON(json['jobRole']),
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
        'showEndTime': json['showEndTime'],
        'showJobRole': json['showJobRole'],
        'shiftTemplateAreaSessions': ((json['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateShiftAreaSessionFromJSON)),
        'shiftTemplateBreaks': ((json['shiftTemplateBreaks'] as Array<any>).map(ShiftTemplateBreakFromJSON)),
        'paidBreakInMinutes': json['paidBreakInMinutes'],
        'unpaidBreakInMinutes': json['unpaidBreakInMinutes'],
        'notes': json['notes'],
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function ShiftTemplateToJSON(value?: ShiftTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'tagIds': value['tagIds'],
        'name': value['name'],
        'locationId': value['locationId'],
        'locations': LocationToJSON(value['locations']),
        'jobRoleId': value['jobRoleId'],
        'jobRole': JobRoleToJSON(value['jobRole']),
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'shiftTemplateAreaSessions': ((value['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateShiftAreaSessionToJSON)),
        'shiftTemplateBreaks': ((value['shiftTemplateBreaks'] as Array<any>).map(ShiftTemplateBreakToJSON)),
        'paidBreakInMinutes': value['paidBreakInMinutes'],
        'unpaidBreakInMinutes': value['unpaidBreakInMinutes'],
        'notes': value['notes'],
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(TagToJSON)),
    };
}

