/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateBillingDetailsData,
  CreateBillingDetailsResponse,
  CreateSetupIntentResponse,
  CreateSubscriptionCancellationResponse,
  CreateSubscriptionQuoteData,
  CreateSubscriptionQuoteResponse,
  EnableCreditRenewalData,
  EnableCreditRenewalsResponse,
  ListBillingPlansResponse,
  ListInvoicesResponse,
  ListNextBillingEventsResponse,
  ListOffersBehavioursResponse,
  ListOffersQuotaResponse,
  ListOffersResponse,
  ListRedeemedOffersResponse,
  ListReferralsResponse,
  RedeemOfferCodeData,
  RedeemOfferResponse,
  SetPaymentMethodData,
  SetPaymentMethodResponse,
  ShowBillingDetailsResponse,
  ShowBillingOverviewResponse,
  ShowBillingPlanResponse,
  ShowCreditLineResponse,
  ShowInvoiceResponse,
  ShowLatestSubscriptionResponse,
  ShowOfferResponse,
  ShowPaymentMethodResponse,
  ShowPendingDowngradeResponse,
  ShowReferrerDetailsResponse,
  ShowSubscriptionCancellationResponse,
  TriggerCreditRenewalData,
  TriggerCreditRenewalResponse,
  UpdateBillingDetailsData,
  UpdateBillingDetailsResponse,
  UpdateBillingSubscriptionData,
  UpdateBillingSubscriptionResponse,
  UpdateCreditRenewalSettingsData,
  UpdateCreditRenewalSettingsResponse,
} from '../models/index';
import {
    CreateBillingDetailsDataFromJSON,
    CreateBillingDetailsDataToJSON,
    CreateBillingDetailsResponseFromJSON,
    CreateBillingDetailsResponseToJSON,
    CreateSetupIntentResponseFromJSON,
    CreateSetupIntentResponseToJSON,
    CreateSubscriptionCancellationResponseFromJSON,
    CreateSubscriptionCancellationResponseToJSON,
    CreateSubscriptionQuoteDataFromJSON,
    CreateSubscriptionQuoteDataToJSON,
    CreateSubscriptionQuoteResponseFromJSON,
    CreateSubscriptionQuoteResponseToJSON,
    EnableCreditRenewalDataFromJSON,
    EnableCreditRenewalDataToJSON,
    EnableCreditRenewalsResponseFromJSON,
    EnableCreditRenewalsResponseToJSON,
    ListBillingPlansResponseFromJSON,
    ListBillingPlansResponseToJSON,
    ListInvoicesResponseFromJSON,
    ListInvoicesResponseToJSON,
    ListNextBillingEventsResponseFromJSON,
    ListNextBillingEventsResponseToJSON,
    ListOffersBehavioursResponseFromJSON,
    ListOffersBehavioursResponseToJSON,
    ListOffersQuotaResponseFromJSON,
    ListOffersQuotaResponseToJSON,
    ListOffersResponseFromJSON,
    ListOffersResponseToJSON,
    ListRedeemedOffersResponseFromJSON,
    ListRedeemedOffersResponseToJSON,
    ListReferralsResponseFromJSON,
    ListReferralsResponseToJSON,
    RedeemOfferCodeDataFromJSON,
    RedeemOfferCodeDataToJSON,
    RedeemOfferResponseFromJSON,
    RedeemOfferResponseToJSON,
    SetPaymentMethodDataFromJSON,
    SetPaymentMethodDataToJSON,
    SetPaymentMethodResponseFromJSON,
    SetPaymentMethodResponseToJSON,
    ShowBillingDetailsResponseFromJSON,
    ShowBillingDetailsResponseToJSON,
    ShowBillingOverviewResponseFromJSON,
    ShowBillingOverviewResponseToJSON,
    ShowBillingPlanResponseFromJSON,
    ShowBillingPlanResponseToJSON,
    ShowCreditLineResponseFromJSON,
    ShowCreditLineResponseToJSON,
    ShowInvoiceResponseFromJSON,
    ShowInvoiceResponseToJSON,
    ShowLatestSubscriptionResponseFromJSON,
    ShowLatestSubscriptionResponseToJSON,
    ShowOfferResponseFromJSON,
    ShowOfferResponseToJSON,
    ShowPaymentMethodResponseFromJSON,
    ShowPaymentMethodResponseToJSON,
    ShowPendingDowngradeResponseFromJSON,
    ShowPendingDowngradeResponseToJSON,
    ShowReferrerDetailsResponseFromJSON,
    ShowReferrerDetailsResponseToJSON,
    ShowSubscriptionCancellationResponseFromJSON,
    ShowSubscriptionCancellationResponseToJSON,
    TriggerCreditRenewalDataFromJSON,
    TriggerCreditRenewalDataToJSON,
    TriggerCreditRenewalResponseFromJSON,
    TriggerCreditRenewalResponseToJSON,
    UpdateBillingDetailsDataFromJSON,
    UpdateBillingDetailsDataToJSON,
    UpdateBillingDetailsResponseFromJSON,
    UpdateBillingDetailsResponseToJSON,
    UpdateBillingSubscriptionDataFromJSON,
    UpdateBillingSubscriptionDataToJSON,
    UpdateBillingSubscriptionResponseFromJSON,
    UpdateBillingSubscriptionResponseToJSON,
    UpdateCreditRenewalSettingsDataFromJSON,
    UpdateCreditRenewalSettingsDataToJSON,
    UpdateCreditRenewalSettingsResponseFromJSON,
    UpdateCreditRenewalSettingsResponseToJSON,
} from '../models/index';

export interface CreateBillingDetailsRequest {
    _with?: Array<string>;
    createBillingDetailsData?: CreateBillingDetailsData;
}

export interface CreateSetupIntentRequest {
    _with?: Array<string>;
}

export interface CreateSubscriptionQuoteRequest {
    createSubscriptionQuoteData?: CreateSubscriptionQuoteData;
}

export interface EnableCreditRenewalsRequest {
    enableCreditRenewalData?: EnableCreditRenewalData;
}

export interface ListBillingPlansRequest {
    _with?: Array<string>;
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListInvoicesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListOffersRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListOffersBehavioursRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListOffersQuotaRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListRedeemedOffersRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListReferralsRequest {
    _with?: Array<string>;
}

export interface RedeemOfferRequest {
    _with?: Array<string>;
    redeemOfferCodeData?: RedeemOfferCodeData;
}

export interface SetPaymentMethodRequest {
    _with?: Array<string>;
    setPaymentMethodData?: SetPaymentMethodData;
}

export interface ShowBillingDetailsRequest {
    _with?: Array<string>;
}

export interface ShowBillingPlanRequest {
    id: number;
}

export interface ShowCreditLineRequest {
    type: ShowCreditLineTypeEnum;
}

export interface ShowInvoiceRequest {
    id: string;
    _with?: Array<string>;
}

export interface ShowLatestSubscriptionRequest {
    _with?: Array<string>;
}

export interface ShowOfferRequest {
    offerCode: string;
    _with?: Array<string>;
}

export interface ShowPendingDowngradeRequest {
    _with?: Array<string>;
}

export interface ShowReferrerDetailsRequest {
    companyRef: string;
}

export interface TriggerCreditRenewalRequest {
    triggerCreditRenewalData?: TriggerCreditRenewalData;
}

export interface UpdateBillingDetailsRequest {
    _with?: Array<string>;
    updateBillingDetailsData?: UpdateBillingDetailsData;
}

export interface UpdateBillingSubscriptionRequest {
    updateBillingSubscriptionData?: UpdateBillingSubscriptionData;
}

export interface UpdateCreditRenewalSettingsRequest {
    updateCreditRenewalSettingsData?: UpdateCreditRenewalSettingsData;
}

/**
 * 
 */
export class BillingApi extends runtime.BaseAPI {

    /**
     * Create billing details for a company
     * Create Billing Details
     */
    async createBillingDetailsRaw(requestParameters: CreateBillingDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateBillingDetailsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-details.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBillingDetailsDataToJSON(requestParameters['createBillingDetailsData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBillingDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Create billing details for a company
     * Create Billing Details
     */
    async createBillingDetails(requestParameters: CreateBillingDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateBillingDetailsResponse> {
        const response = await this.createBillingDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a stripe setup intent.
     * Create Setup Intent
     */
    async createSetupIntentRaw(requestParameters: CreateSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSetupIntentResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-payment-intent.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/intent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSetupIntentResponseFromJSON(jsonValue));
    }

    /**
     * Create a stripe setup intent.
     * Create Setup Intent
     */
    async createSetupIntent(requestParameters: CreateSetupIntentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSetupIntentResponse> {
        const response = await this.createSetupIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark the active subscription as cancelling at the end of the subscription period.
     * Create Subscription Cancellation
     */
    async createSubscriptionCancellationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSubscriptionCancellationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-cancellation.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/cancellations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSubscriptionCancellationResponseFromJSON(jsonValue));
    }

    /**
     * Mark the active subscription as cancelling at the end of the subscription period.
     * Create Subscription Cancellation
     */
    async createSubscriptionCancellation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSubscriptionCancellationResponse> {
        const response = await this.createSubscriptionCancellationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a quote detailing the costs of upgrading to a new subscription.
     * Create Subscription Quote
     */
    async createSubscriptionQuoteRaw(requestParameters: CreateSubscriptionQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSubscriptionQuoteResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-quote.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/subscription/quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubscriptionQuoteDataToJSON(requestParameters['createSubscriptionQuoteData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSubscriptionQuoteResponseFromJSON(jsonValue));
    }

    /**
     * Create a quote detailing the costs of upgrading to a new subscription.
     * Create Subscription Quote
     */
    async createSubscriptionQuote(requestParameters: CreateSubscriptionQuoteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSubscriptionQuoteResponse> {
        const response = await this.createSubscriptionQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the pending billing downgrade if one exists, based on the current company context.
     * Delete Pending Downgrade
     */
    async deletePendingDowngradeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-downgrade.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/downgrades`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the pending billing downgrade if one exists, based on the current company context.
     * Delete Pending Downgrade
     */
    async deletePendingDowngrade(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePendingDowngradeRaw(initOverrides);
    }

    /**
     * Stop the active subscription from being cancelled at the end of the subscription period.
     * Delete Subscription Cancellation
     */
    async deleteSubscriptionCancellationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-cancellation.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/cancellations`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stop the active subscription from being cancelled at the end of the subscription period.
     * Delete Subscription Cancellation
     */
    async deleteSubscriptionCancellation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSubscriptionCancellationRaw(initOverrides);
    }

    /**
     * Enable credit renewals for a specific credit type.
     * Enable Credit Renewals
     */
    async enableCreditRenewalsRaw(requestParameters: EnableCreditRenewalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnableCreditRenewalsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-credits.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/credits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnableCreditRenewalDataToJSON(requestParameters['enableCreditRenewalData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnableCreditRenewalsResponseFromJSON(jsonValue));
    }

    /**
     * Enable credit renewals for a specific credit type.
     * Enable Credit Renewals
     */
    async enableCreditRenewals(requestParameters: EnableCreditRenewalsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnableCreditRenewalsResponse> {
        const response = await this.enableCreditRenewalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Display a list of available billing plans
     * List Billing Plans
     */
    async listBillingPlansRaw(requestParameters: ListBillingPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListBillingPlansResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/billing/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListBillingPlansResponseFromJSON(jsonValue));
    }

    /**
     * Display a list of available billing plans
     * List Billing Plans
     */
    async listBillingPlans(requestParameters: ListBillingPlansRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListBillingPlansResponse> {
        const response = await this.listBillingPlansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all invoices
     * List Invoices
     */
    async listInvoicesRaw(requestParameters: ListInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListInvoicesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-invoice.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListInvoicesResponseFromJSON(jsonValue));
    }

    /**
     * List all invoices
     * List Invoices
     */
    async listInvoices(requestParameters: ListInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListInvoicesResponse> {
        const response = await this.listInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the next billing events for your company
     * List Next Billing Events
     */
    async listNextBillingEventsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListNextBillingEventsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-event.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/next`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListNextBillingEventsResponseFromJSON(jsonValue));
    }

    /**
     * List the next billing events for your company
     * List Next Billing Events
     */
    async listNextBillingEvents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListNextBillingEventsResponse> {
        const response = await this.listNextBillingEventsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all offers
     * List Offer Quotas
     */
    async listOffersRaw(requestParameters: ListOffersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOffersResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-offer.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/offers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOffersResponseFromJSON(jsonValue));
    }

    /**
     * List all offers
     * List Offer Quotas
     */
    async listOffers(requestParameters: ListOffersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOffersResponse> {
        const response = await this.listOffersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all offer behaviours for a company
     * List Offer Behaviours
     */
    async listOffersBehavioursRaw(requestParameters: ListOffersBehavioursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOffersBehavioursResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-offer.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/offers-behaviour`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOffersBehavioursResponseFromJSON(jsonValue));
    }

    /**
     * List all offer behaviours for a company
     * List Offer Behaviours
     */
    async listOffersBehaviours(requestParameters: ListOffersBehavioursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOffersBehavioursResponse> {
        const response = await this.listOffersBehavioursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all offer quotas for a company
     * List Offer Quotas
     */
    async listOffersQuotaRaw(requestParameters: ListOffersQuotaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOffersQuotaResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-offer.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/offers-quota`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOffersQuotaResponseFromJSON(jsonValue));
    }

    /**
     * List all offer quotas for a company
     * List Offer Quotas
     */
    async listOffersQuota(requestParameters: ListOffersQuotaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOffersQuotaResponse> {
        const response = await this.listOffersQuotaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all redeemed offers for a company
     * List Redeemed Offers
     */
    async listRedeemedOffersRaw(requestParameters: ListRedeemedOffersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListRedeemedOffersResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-offer.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/offers-redeemed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListRedeemedOffersResponseFromJSON(jsonValue));
    }

    /**
     * List all redeemed offers for a company
     * List Redeemed Offers
     */
    async listRedeemedOffers(requestParameters: ListRedeemedOffersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListRedeemedOffersResponse> {
        const response = await this.listRedeemedOffersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a list of your referrals
     * List Referrals
     */
    async listReferralsRaw(requestParameters: ListReferralsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListReferralsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-referrals.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListReferralsResponseFromJSON(jsonValue));
    }

    /**
     * Show a list of your referrals
     * List Referrals
     */
    async listReferrals(requestParameters: ListReferralsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListReferralsResponse> {
        const response = await this.listReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Redeem an offer code
     * Redeem Offer
     */
    async redeemOfferRaw(requestParameters: RedeemOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedeemOfferResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-offer.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/offers/redeem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedeemOfferCodeDataToJSON(requestParameters['redeemOfferCodeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedeemOfferResponseFromJSON(jsonValue));
    }

    /**
     * Redeem an offer code
     * Redeem Offer
     */
    async redeemOffer(requestParameters: RedeemOfferRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedeemOfferResponse> {
        const response = await this.redeemOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Attempt to take payment on any outstanding invoices and restart any failed subscriptions.
     * Restore Account
     */
    async restoreAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-subscription.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/restore`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Attempt to take payment on any outstanding invoices and restart any failed subscriptions.
     * Restore Account
     */
    async restoreAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.restoreAccountRaw(initOverrides);
    }

    /**
     * Set the companies payment method.
     * Set Payment Method
     */
    async setPaymentMethodRaw(requestParameters: SetPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetPaymentMethodResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-payment-method.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPaymentMethodDataToJSON(requestParameters['setPaymentMethodData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetPaymentMethodResponseFromJSON(jsonValue));
    }

    /**
     * Set the companies payment method.
     * Set Payment Method
     */
    async setPaymentMethod(requestParameters: SetPaymentMethodRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetPaymentMethodResponse> {
        const response = await this.setPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show billing details for a company
     * Show Billing Details
     */
    async showBillingDetailsRaw(requestParameters: ShowBillingDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowBillingDetailsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-details.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowBillingDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Show billing details for a company
     * Show Billing Details
     */
    async showBillingDetails(requestParameters: ShowBillingDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowBillingDetailsResponse> {
        const response = await this.showBillingDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a basic overview of a companies billing setup.
     * Show Billing Overview
     */
    async showBillingOverviewRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowBillingOverviewResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-overview.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowBillingOverviewResponseFromJSON(jsonValue));
    }

    /**
     * Show a basic overview of a companies billing setup.
     * Show Billing Overview
     */
    async showBillingOverview(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowBillingOverviewResponse> {
        const response = await this.showBillingOverviewRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show details about a billing plan
     * Show Billing Plan
     */
    async showBillingPlanRaw(requestParameters: ShowBillingPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowBillingPlanResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showBillingPlan().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/billing/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowBillingPlanResponseFromJSON(jsonValue));
    }

    /**
     * Show details about a billing plan
     * Show Billing Plan
     */
    async showBillingPlan(requestParameters: ShowBillingPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowBillingPlanResponse> {
        const response = await this.showBillingPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a given credit line.
     * Show Credit Line
     */
    async showCreditLineRaw(requestParameters: ShowCreditLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowCreditLineResponse>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling showCreditLine().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-credits.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/credits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowCreditLineResponseFromJSON(jsonValue));
    }

    /**
     * Show a given credit line.
     * Show Credit Line
     */
    async showCreditLine(requestParameters: ShowCreditLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowCreditLineResponse> {
        const response = await this.showCreditLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Invoice
     */
    async showInvoiceRaw(requestParameters: ShowInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowInvoiceResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showInvoice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-invoice.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/invoices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowInvoiceResponseFromJSON(jsonValue));
    }

    /**
     * Show Invoice
     */
    async showInvoice(requestParameters: ShowInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowInvoiceResponse> {
        const response = await this.showInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shows the currently subscribed billing plan for the company.
     * Show Current Subscription
     */
    async showLatestSubscriptionRaw(requestParameters: ShowLatestSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLatestSubscriptionResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-subscription.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/subscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLatestSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Shows the currently subscribed billing plan for the company.
     * Show Current Subscription
     */
    async showLatestSubscription(requestParameters: ShowLatestSubscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLatestSubscriptionResponse> {
        const response = await this.showLatestSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show information about an offer code.
     * Show Offer
     */
    async showOfferRaw(requestParameters: ShowOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowOfferResponse>> {
        if (requestParameters['offerCode'] == null) {
            throw new runtime.RequiredError(
                'offerCode',
                'Required parameter "offerCode" was null or undefined when calling showOffer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-offer.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/offers/{offerCode}`.replace(`{${"offerCode"}}`, encodeURIComponent(String(requestParameters['offerCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowOfferResponseFromJSON(jsonValue));
    }

    /**
     * Show information about an offer code.
     * Show Offer
     */
    async showOffer(requestParameters: ShowOfferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowOfferResponse> {
        const response = await this.showOfferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the companies payment details on file.
     * Show Payment Method
     */
    async showPaymentMethodRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowPaymentMethodResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-payment-method.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/payment-method`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowPaymentMethodResponseFromJSON(jsonValue));
    }

    /**
     * Show the companies payment details on file.
     * Show Payment Method
     */
    async showPaymentMethod(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowPaymentMethodResponse> {
        const response = await this.showPaymentMethodRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the pending billing downgrade if one exists, based on the current company context.
     * Show Pending Downgrade
     */
    async showPendingDowngradeRaw(requestParameters: ShowPendingDowngradeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowPendingDowngradeResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-downgrade.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/downgrades`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowPendingDowngradeResponseFromJSON(jsonValue));
    }

    /**
     * Show the pending billing downgrade if one exists, based on the current company context.
     * Show Pending Downgrade
     */
    async showPendingDowngrade(requestParameters: ShowPendingDowngradeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowPendingDowngradeResponse> {
        const response = await this.showPendingDowngradeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details about a referrer
     * Show Referrer Details
     */
    async showReferrerDetailsRaw(requestParameters: ShowReferrerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowReferrerDetailsResponse>> {
        if (requestParameters['companyRef'] == null) {
            throw new runtime.RequiredError(
                'companyRef',
                'Required parameter "companyRef" was null or undefined when calling showReferrerDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/billing/referrer/{companyRef}`.replace(`{${"companyRef"}}`, encodeURIComponent(String(requestParameters['companyRef']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowReferrerDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Show details about a referrer
     * Show Referrer Details
     */
    async showReferrerDetails(requestParameters: ShowReferrerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowReferrerDetailsResponse> {
        const response = await this.showReferrerDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the current cancellation, if applicable.
     * Show Subscription Cancellation
     */
    async showSubscriptionCancellationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowSubscriptionCancellationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-cancellation.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/cancellations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowSubscriptionCancellationResponseFromJSON(jsonValue));
    }

    /**
     * Show the current cancellation, if applicable.
     * Show Subscription Cancellation
     */
    async showSubscriptionCancellation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowSubscriptionCancellationResponse> {
        const response = await this.showSubscriptionCancellationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Trigger a manual renewal of credits that are in arrears.
     * Trigger Credit Renewal
     */
    async triggerCreditRenewalRaw(requestParameters: TriggerCreditRenewalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TriggerCreditRenewalResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-credits.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/credits/renew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TriggerCreditRenewalDataToJSON(requestParameters['triggerCreditRenewalData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TriggerCreditRenewalResponseFromJSON(jsonValue));
    }

    /**
     * Trigger a manual renewal of credits that are in arrears.
     * Trigger Credit Renewal
     */
    async triggerCreditRenewal(requestParameters: TriggerCreditRenewalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TriggerCreditRenewalResponse> {
        const response = await this.triggerCreditRenewalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update billing details for a company
     * Update Billing Details
     */
    async updateBillingDetailsRaw(requestParameters: UpdateBillingDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateBillingDetailsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-details.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/details`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBillingDetailsDataToJSON(requestParameters['updateBillingDetailsData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateBillingDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Update billing details for a company
     * Update Billing Details
     */
    async updateBillingDetails(requestParameters: UpdateBillingDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateBillingDetailsResponse> {
        const response = await this.updateBillingDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the current billing subscription
     * Update Billing Subscription
     */
    async updateBillingSubscriptionRaw(requestParameters: UpdateBillingSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateBillingSubscriptionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-subscription.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBillingSubscriptionDataToJSON(requestParameters['updateBillingSubscriptionData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateBillingSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Update the current billing subscription
     * Update Billing Subscription
     */
    async updateBillingSubscription(requestParameters: UpdateBillingSubscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateBillingSubscriptionResponse> {
        const response = await this.updateBillingSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update how renewals for a specific credit type are handled.
     * Update Credit Renewal Settings
     */
    async updateCreditRenewalSettingsRaw(requestParameters: UpdateCreditRenewalSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCreditRenewalSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.billing-credits.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/billing/credits`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCreditRenewalSettingsDataToJSON(requestParameters['updateCreditRenewalSettingsData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCreditRenewalSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update how renewals for a specific credit type are handled.
     * Update Credit Renewal Settings
     */
    async updateCreditRenewalSettings(requestParameters: UpdateCreditRenewalSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCreditRenewalSettingsResponse> {
        const response = await this.updateCreditRenewalSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum ShowCreditLineTypeEnum {
    Referral = 'Referral',
    Sms = 'SMS'
}
