/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftAreaSessionsData } from './ShiftAreaSessionsData';
import {
    ShiftAreaSessionsDataFromJSON,
    ShiftAreaSessionsDataFromJSONTyped,
    ShiftAreaSessionsDataToJSON,
} from './ShiftAreaSessionsData';

/**
 * 
 * @export
 * @interface BulkCreateShiftAreaSessionsData
 */
export interface BulkCreateShiftAreaSessionsData {
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftAreaSessionsData
     */
    shiftId: number;
    /**
     * 
     * @type {Array<ShiftAreaSessionsData>}
     * @memberof BulkCreateShiftAreaSessionsData
     */
    shiftAreaSessions: Array<ShiftAreaSessionsData>;
}

/**
 * Check if a given object implements the BulkCreateShiftAreaSessionsData interface.
 */
export function instanceOfBulkCreateShiftAreaSessionsData(value: object): boolean {
    if (!('shiftId' in value)) return false;
    if (!('shiftAreaSessions' in value)) return false;
    return true;
}

export function BulkCreateShiftAreaSessionsDataFromJSON(json: any): BulkCreateShiftAreaSessionsData {
    return BulkCreateShiftAreaSessionsDataFromJSONTyped(json, false);
}

export function BulkCreateShiftAreaSessionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateShiftAreaSessionsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftId': json['shiftId'],
        'shiftAreaSessions': ((json['shiftAreaSessions'] as Array<any>).map(ShiftAreaSessionsDataFromJSON)),
    };
}

export function BulkCreateShiftAreaSessionsDataToJSON(value?: BulkCreateShiftAreaSessionsData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftId': value['shiftId'],
        'shiftAreaSessions': ((value['shiftAreaSessions'] as Array<any>).map(ShiftAreaSessionsDataToJSON)),
    };
}

