/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePayTypeData
 */
export interface UpdatePayTypeData {
    /**
     * 
     * @type {string}
     * @memberof UpdatePayTypeData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePayTypeData
     */
    mode?: UpdatePayTypeDataModeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdatePayTypeData
     */
    hourlyRate?: number;
}

/**
* @export
* @enum {string}
*/
export enum UpdatePayTypeDataModeEnum {
    Percentage = 'Percentage',
    Fixed = 'Fixed'
}


/**
 * Check if a given object implements the UpdatePayTypeData interface.
 */
export function instanceOfUpdatePayTypeData(value: object): boolean {
    return true;
}

export function UpdatePayTypeDataFromJSON(json: any): UpdatePayTypeData {
    return UpdatePayTypeDataFromJSONTyped(json, false);
}

export function UpdatePayTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePayTypeData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'mode': json['mode'] == null ? undefined : json['mode'],
        'hourlyRate': json['hourlyRate'] == null ? undefined : json['hourlyRate'],
    };
}

export function UpdatePayTypeDataToJSON(value?: UpdatePayTypeData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'mode': value['mode'],
        'hourlyRate': value['hourlyRate'],
    };
}

