<template>
  <keep-alive>
    <div
      ref="panelContent"
      :class="{ show: value, overlay: useBackgroundOverlay }"
      class="background-overlay"
      @click="$emit('input', false)"
    >
      <aside
        class="panel"
        :class="side"
        @click.stop
      >
        <slot name="body" />
      </aside>
    </div>
  </keep-alive>
</template>

<script>
const PARENT_SELECTOR = '#main';

export default {
  name: 'SlidePanel',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      default: 'right',
      validator: (p) => ['left', 'right'].includes(p),
    },
    useBackgroundOverlay: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      windowScroll: 0,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.windowScroll = window.scrollY;
        document.body.style.overflow = v ? 'hidden' : '';
        document.body.style.height = v ? '100vh' : '';
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      document
        .querySelector(PARENT_SELECTOR)
        .appendChild(this.$refs.panelContent);
    });
  },
  beforeDestroy() {
    document.body.style.overflow = '';
    document.body.style.height = '';
    window.scrollTo(0, this.windowScroll);
    this.$refs.panelContent.parentNode?.removeChild(this.$refs.panelContent);
  },
};
</script>

<style scoped lang="scss">
.background-overlay {
  --width: 380px;

  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 125;

  &.overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.show {
    visibility: visible;

    .panel {
      visibility: visible;
      transition: all 250ms ease;
      position: relative;

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
        @media screen and (min-width: $lg) {
          // Move in from desktop nav
          left: 65px;
        }
      }
    }
  }

  .panel {
    display: block;
    visibility: hidden;
    transition: all 250ms ease;

    &.right {
      right: calc(var(--width) * -1);
      margin-left: auto;
    }

    &.left {
      left: calc(var(--width) * -1);
      margin-right: auto;
    }

    width: 90%;
    height: 100%;
    position: relative;

    background-color: #fff;
    box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: $xs) {
      width: var(--width);
    }
  }
}
</style>
