/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeatherReportData
 */
export interface WeatherReportData {
    /**
     * 
     * @type {number}
     * @memberof WeatherReportData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WeatherReportData
     */
    main: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherReportData
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherReportData
     */
    icon: string;
}

/**
 * Check if a given object implements the WeatherReportData interface.
 */
export function instanceOfWeatherReportData(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('main' in value)) return false;
    if (!('description' in value)) return false;
    if (!('icon' in value)) return false;
    return true;
}

export function WeatherReportDataFromJSON(json: any): WeatherReportData {
    return WeatherReportDataFromJSONTyped(json, false);
}

export function WeatherReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherReportData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'main': json['main'],
        'description': json['description'],
        'icon': json['icon'],
    };
}

export function WeatherReportDataToJSON(value?: WeatherReportData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'main': value['main'],
        'description': value['description'],
        'icon': value['icon'],
    };
}

